<template>
  <div class="cardset" @mouseleave="mouseleaveGridItem">
    <div class="gridtitle wordellipsis">
      {{ chart.reqObj.title }}
    </div>
    <div class="setbox" @click.stop="clickChartset(chart.i)">
      <span>
        <i class="el-icon-s-tools"></i>
      </span>
      <div class="hoverul" v-if="cardsetidx === chart.i">
        <ul>
          <li v-if="$commonJs._point('DS_F0003')">
            <a :href="chart.url + '?mdashboardID=' + dashboardID" target="_blank"> <i class="el-icon-edit"></i>编辑 </a>
          </li>
          <li @click="refresh"><i class="iconfont iconshuaxin"></i>刷新</li>
          <li v-if="$commonJs._point('DS_F0003') && chart.cardinfos" @click="setstyle"><i class="iconfont iconshoujiduan"></i>移动端样式</li>
          <li v-if="$commonJs._point('BI报表_卡片_删除')" @click="delmobile"><i class="iconfont iconshanchu"></i>移动端移除</li>
        </ul>
      </div>
    </div>
    <el-drawer title="移动端样式" :visible.sync="drawer" direction="rtl" :append-to-body="true" :wrapperClosable="false" size="350px">
      <div class="drawer-main">
        <styleSet :chart="chart" :chartidx="chartidx" @set-change="debounceset" />
      </div>
      <div class="footbtns dialog-footer">
        <el-button class="cancel" size="small" @click="drawer = false">取 消</el-button>
        <el-button type="primary" size="small" @click="save">保 存</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import styleSet from './styleSet/index.vue'
import BI_config from '@/utils/config'
import bi_echarts from 'mallcoo_bi_echarts'
const Table = bi_echarts.Table
import { updateMobileBookmark, updateMobilePosition } from '@/api/mobileapi.js'

export default {
  name: 'cardset',
  props: ['dashboard', 'gridData', 'chart', 'chartidx'],
  data() {
    return {
      cardsetidx: -1,
      drawer: false,
      searchParam: '',
      searchRes: '',
      setting: '',
      analysisType: '',
      analysisUsage: '',
      dashboardID: '',

      tableObj: {},

      debounce: _.debounce(
        isname => {
          this.setchange(isname)
        },
        300,
        {
          trailing: true,
        }
      ),
    }
  },
  components: { styleSet },
  computed: {
    ...mapState({
      system: state => state.system,
      basicInfo: state => state.basicInfo,
      festivals: state => state.festivals,
      weathers: state => state.weathers,
      projectMalls: state => state.projectMalls,
    }),
  },

  created() {
    if (!this.chart.cardinfos) return
    this.analysisType = this.chart.cardinfos.cardopts.category
    this.analysisUsage = this.chart.cardinfos.cardopts.usage
    this.searchRes = this.chart.cardinfos.result
    this.searchparams = this.chart.cardinfos.parameter
    this.dashboardID = Number(this.$route.hash.replace('#/', ''))
  },
  methods: {
    debounceset(item) {
      this.setting = item.setting
      // const isname = item.isname
      this.debounce()
    },
    searchchange() {},
    setchange(isname) {
      let isAllDimValues = false
      let copyChart = _.cloneDeep(this.chart)
      // if (!this.setting.propSet && this.analysisType && this.analysisUsage != 'Funnel') return
      // this.chartoption.chartSetting = this.setting
      // this.$emit('changestatic', this.setting)
      // this.$emit('changetype', this.chartoption)
      if (this.setting.isAllDimValues) {
        isAllDimValues = true
        delete this.setting.isAllDimValues
      }
      let searchRes = {
        rows: [...this.searchRes.rows],
        columns: [...this.searchRes.columns],
      }
      searchRes = Object.assign({}, this.searchRes, searchRes)
      let d1 = this.searchparams.code == 'funnel' ? searchRes : this.getFilterRes(searchRes)
      this.getTableOption(d1)
      // copyChart.orginResult = d1
      // copyChart.reqObj.parameter = this.searchparams
      // this.chartData.option = this.searchRes.rows.length == 0 ? '' : Behavior(this, d1, this.setting, this.searchparams, this.opts, this.tableInitData)
      // copyChart.chartSetting = this.setting
      // if (this.setData.chartSetting.type == 'card' && this.chartData.option.arr) {
      //   this.initcurcard()
      // }
      const category = this.analysisType && this.analysisType.category == 'template' ? 'template' : this.analysisType
      if (category === 'template' && this.analysisUsage === 'Path' && this.setting.type === 'table') {
        this.setting.type = 'table_path'
      }
      // const hotmapdata = this.chart.cardinfos && this.chart.cardinfos.hotmapdata ? this.chart.cardinfos.hotmapdata : ''
      copyChart.cardinfos.settings = this.setting
      copyChart.cardinfos.result = d1
      if (isAllDimValues) {
        this.$emit('chartchange', { copyChart, isAllDimValues })
      } else {
        this.$emit('chartchange', { copyChart })
      }

      // if (category == 'segment') {
      //   cardinfos.cardopts.uniqueID = d1.uniqueID
      //   cardinfos.cardopts.segmentName = this.setting.nameCN || this.setting.nameEN
      // }
      // this.chart.cardinfos.result = d1
      // this.mapkey++
      // this.chartKey++
      // if (!isname) {
      //   this.chartKey = Math.random()
      // }
    },

    clickChartset(i) {
      if (this.cardsetidx == i) {
        this.cardsetidx = -1
      } else {
        this.cardsetidx = i
      }
      this.$emit('chartsetidxChange', this.cardsetidx)
    },
    mouseleaveGridItem() {
      this.cardsetidx = -1
    },
    refresh() {
      this.$emit('refresh')
    },
    setstyle() {
      this.drawer = true
    },
    delmobile() {
      this.$confirm('确认从移动端移除当前卡片?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          this.$emit('delmobile')
        })
        .catch(() => {})
    },

    getTableOption(d1) {
      if (this.setting.type == 'table') {
        d1 = _.cloneDeep(d1)
      }
      this.orginResult = d1
      const styleSet = this.setting.styleSet
      //如果 切换时没有累计值 allDimValues
      if (!styleSet.allDimValues) {
        d1.columns = d1.columns.filter(item => {
          return item.usage !== 'History'
        })
      }
      let groups = d1.columns.filter(item => {
        return item.usage === 'Group'
      })
      let prop = d1.columns.filter(item => {
        return item.usage !== 'Group'
      })

      let opts = {
        ...this.setting,
        analysisType: this.analysisType,
      }
      this.tableObj = new Table({
        opts,
        columns: d1.columns,
        result: d1.rows,
      })
      // Table 内部修改了rows 需要同步更新d1
      d1.rows = this.tableObj.params.result
      this.tableInitData = this.tableObj.init()
    },

    //根据过滤条件 重组res
    getFilterRes(searchRes) {
      const copyColumns = JSON.parse(JSON.stringify(searchRes.columns))
      let res = searchRes
      let filterSet = this.setting && this.setting.filterSet
      let groups = copyColumns.filter(x => {
        return x.usage == 'Group'
      })
      let measures = copyColumns.filter(x => {
        return x.usage == 'Measure'
      })
      let row = searchRes.rows.filter(x => {
        let s = ''
        let isUnknown = false
        groups.forEach((g, gidx) => {
          //隐藏未知
          if (filterSet.hideUnknown) {
            if (x[g.alias] == '-') {
              isUnknown = true
            }
          }
          if (gidx < groups.length - 1 && groups.length > 1) {
            s += x[g.alias] + '[|]'
          } else {
            s += x[g.alias]
          }
        })
        x.sys_gen_key = s
        // 指标类型 PERCENTAGE_DIRECT 的值 *0.01
        if (!x.per_direct) {
          copyColumns.forEach(m => {
            if (m.displayType == 'PERCENTAGE_DIRECT') {
              x[m.alias] = x[m.alias] * 0.01
            }
          })
          x.per_direct = true
        }
        return x && !isUnknown
      })
      if (copyColumns.length > 0) {
        //过滤改变指标名称时 同步修改返回结果的指标名称和同环比的指标名称
        const propsetGroup = this.setting?.propSet?.groups ?? []
        let oldname = ''
        let columns = copyColumns.filter(x => {
          if (x.usage === 'Group') {
            const grp = propsetGroup.find(g => g.fullAlias === x.fullAlias)
            if (grp) x.title = grp.title
          }
          let targ =
            filterSet &&
            filterSet.columns &&
            filterSet.columns.find(t => {
              if (t.fullAlias == x.fullAlias) {
                oldname = x.title
              }
              return t.fullAlias == x.fullAlias
            })

          let isshow = targ && (!targ.usage || targ.usage === 'Measure') ? targ.selected : true
          x.title = targ && (!targ.usage || targ.usage === 'Measure') ? targ.name : this.reviseName(x)
          if (!targ) {
            let meatarg =
              filterSet &&
              filterSet.columns &&
              filterSet.columns.find(t => {
                return x.alias.includes(t.alias)
              })
            if (meatarg) {
              x.title = x.title.replace(oldname, meatarg.name)
            }
          }
          return isshow
        })
        //指标数值过滤开启时，根据指标start，end过滤数值  //都不为空 1个为空 都为空
        if (filterSet.filterValue) {
          row = row.filter(x => {
            let istrue = true
            filterSet.columns.forEach(c => {
              let val =
                c.filterCode && x[c.filterCode]
                  ? typeof x[c.filterCode] === 'number'
                    ? x[c.filterCode]
                    : x[c.filterCode] == '-'
                    ? '-'
                    : parseFloat(x[c.filterCode].replace(/[,]/g, ''))
                  : x[c.alias]
              if ((c.start || c.start === 0) && (c.end || c.end === 0) && c.start <= c.end && val >= c.start && val <= c.end) {
              } else if ((c.start || c.start === 0) && (c.end || c.end === 0) && c.start > c.end && val >= c.start) {
              } else if (!c.start && c.start !== 0 && (c.end || c.end === 0) && val <= c.end) {
              } else if ((c.start || c.start === 0) && !c.end && c.end !== 0 && val >= c.start) {
              } else if (!c.start && c.start !== 0 && !c.end && c.end !== 0) {
              } else {
                istrue = false
              }
            })
            return istrue
          })
        }

        res.rows = row
        res.columns = columns
        // 漏斗
        if (res.funnels) {
          let funnels = res.funnels.map(x => {
            let itemres = this.getFunnelSteps(x, this.setting)
            return itemres
          })
          res.funnels = funnels
        }
      } else {
        res = searchRes
      }
      return res
    },
    getFunnelSteps(searchRes, settings) {
      const copyColumns = JSON.parse(JSON.stringify(searchRes.columns))
      let res = searchRes
      const propsetGroup = settings?.propSet?.groups ?? []

      let columns = copyColumns.map(x => {
        if (x.usage === 'Group') {
          const grp = propsetGroup.find(g => g.fullAlias === x.fullAlias)
          if (grp) x.title = grp.title
        }
        return x
      })
      res.columns = columns
      return res
    },
    reviseName(column) {
      let name = column.title
      //是否同环比值
      this.searchRes.columns.forEach(x => {
        if (x.alias + '_contrast' == column.alias) {
          name = '对比' + x.title
        }
        if (x.alias + '_last' == column.alias) {
          name = '去年同期' + x.title
        }
        if (x.alias + '_circle' == column.alias) {
          name = '上一周期' + x.title
        }
      })
      return name
    },
    updatePosition(bookmarks) {
      const param = {
        dashboardID: this.$route.hash.replace('#/', ''),
        bookmarks,
      }
      try {
        updateMobilePosition(param).then(() => {
          this.updateBookmark()
        })
      } catch (err) {}
    },
    updateBookmark() {
      const param = {
        dashboardID: Number(this.$route.hash.replace('#/', '')),
        bookmarkID: this.chart.id,
        settings: JSON.stringify(this.setting),
      }
      updateMobileBookmark(param).then(() => {
        this.drawer = false
      })
    },
    save() {
      const mobileBookmarks = this.dashboard?.mobileSettings?.bookmarks ?? null
      if (!mobileBookmarks) {
        const bookmarks = this.gridData.map(x => {
          return {
            id: x.id,
            position: {
              row: x.y,
              col: x.x,
              x: x.w,
              y: x.h,
            },
          }
        })
        this.updatePosition(bookmarks)
      } else {
        this.updateBookmark()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cardset {
  display: flex;
  .gridtitle {
    flex: 1;
    max-width: 100%;
  }
  .setbox {
    position: relative;
    margin-right: 0;
    span {
      font-size: 16px;
      cursor: pointer;
      i {
        color: #adb5bd;
        font-size: 16px;
        &:hover {
          @include high_color1($theme-light);
        }
      }
    }
    .hoverul {
      position: absolute;
      z-index: 223;
      top: 10px;
      right: 0;
      padding-top: 20px;
    }
    ul {
      width: 125px;
      @include theme_bg1($theme-light);
      box-shadow: 0 0 15px 0 rgba(136, 152, 170, 0.15);
      border-radius: 6px;
      padding: 10px 0 10px 15px;
      li {
        cursor: pointer;
        @include theme_color2($theme-light);
        font-size: 12px;
        line-height: 26px;
        text-align: left;
        a {
          display: block;
          @include theme_color2($theme-light);
          &:hover {
            @include high_color1($theme-light);
          }
        }
        .apiid {
          font-size: 12px;
          display: inline-block;
          margin-right: 3px;
        }
        i {
          color: #adb5bd;
          margin-right: 5px;
          font-size: 12px;
        }
        &:hover {
          @include high_color1($theme-light);
        }
        .export-excel-wrapper {
          display: inline-block;
          font-size: 12px;
        }
        .download {
          font-size: 12px;
          @include theme_color2($theme-light);
          &:hover {
            @include high_color1($theme-light);
          }
        }
        &.downitem {
          position: relative;
          &:hover {
            .downbox {
              display: block;
            }
          }
        }
        .downbox {
          display: none;
          position: absolute;
          left: 50px;
          top: 0;
          z-index: 99;
          width: 150px;
          padding: 5px;
          background: #fff;
          border: 1px solid #e9ecef;
          border-radius: 3px;
          span {
            display: block;
            font-size: 14px;
            color: #333;
            &:hover {
              @include high_color1($theme-light);
            }
          }
        }
      }
    }
  }
}
</style>
