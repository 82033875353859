import initMarkV2 from '@/script/bookmarkV2/initMarkV2'
const mCommonJS = {
  //加工过滤
  initFilter: (searchRes, p) => {
    const searchParam = p.parameter
    let set = p.settings
    let columns = JSON.parse(JSON.stringify(searchRes.columns))
    let rows = searchRes.rows

    let newFilterSet = {
      columns: [],
      codes: null,
      hideUnknown: set.filterSet ? set.filterSet.hideUnknown : false,
      filterValue: set.filterSet ? set.filterSet.filterValue : false,
    }

    //过滤的指标名和start，end 根据对比查询条件的事件指标是否修改 来显示当前值还是默认值
    //模板分析的 分布和留存不需要 columns
    // 高级分析只取 结果里面的名称
    const templateusage = searchParam.usage
    const contrastType = searchParam.contrastType
    if (!['Scatter', 'Retention'].includes(templateusage)) {
      columns.forEach(x => {
        let fsetcol =
          set.filterSet &&
          set.filterSet.columns.find(s => {
            return s.fullAlias == x.fullAlias
          })
        let list = fsetcol ? fsetcol : {}
        if (x.usage == 'Measure') {
          //同环比
          list['filterCode'] = fsetcol && fsetcol.filterCode ? fsetcol.filterCode : mCommonJS.getFilterCode(contrastType, fsetcol, x.alias)
          //暂存同环比原始值
          const valarr = mCommonJS.getValarr(contrastType, rows, x.alias)
          list['valList'] = valarr

          //查看是否和当前的事件指标相同的
          list['fullAlias'] = x.fullAlias
          list['alias'] = x.alias
          list['usage'] = fsetcol && fsetcol.usage ? fsetcol.usage : x.usage
          list['selected'] = fsetcol ? fsetcol.selected : true
          list['name'] = fsetcol ? fsetcol.name : x.title
          list['start'] = fsetcol && newFilterSet.filterValue ? fsetcol.start : valarr.current.start
          list['end'] = fsetcol && newFilterSet.filterValue ? fsetcol.end : valarr.current.end
        } else {
          if (JSON.stringify(list) !== '{}') {
            list['alias'] = x.alias
          }
        }
        if (JSON.stringify(list) !== '{}') {
          newFilterSet.columns.push(list)
        }
      })
    }
    set.filterSet = newFilterSet
  },
  getFilterCode(contrastType, fsetcol, alias) {
    let code = fsetcol && fsetcol.filterCode ? fsetcol.filterCode : fsetcol && fsetcol.alias ? fsetcol.alias : alias
    //若code 包含 Rate 且 不包含 当前对比类型的Rate 取alias
    if (
      code.includes('Rate') &&
      !(
        (code.includes('lastRate') && contrastType.includes('Last')) ||
        (code.includes('contrastRate') && contrastType.includes('Contrast')) ||
        (code.includes('circleRate') && contrastType.includes('Circle'))
      )
    ) {
      code = alias
    }
    if (!code.includes('Rate') && fsetcol && fsetcol.filterCode !== fsetcol.alias) {
      code = alias
    }
    return code
  },
  getValarr(contrastType, rows, alias) {
    let list = {}
    //指标值
    const nums = rows.map(r => {
      return r[alias]
    })
    const start = Math.min.apply(null, nums)
    const end = Math.max.apply(null, nums)
    list['current'] = { start: start, end: end }
    //对比
    if (contrastType && contrastType.includes('Contrast')) {
      let nums_c = rows.map(r => {
        let val = r[alias + '_contrastRate']
        if (String(val).includes('%')) {
          val = parseFloat(val.replace(/[,]/g, ''))
        }
        return val
      })
      nums_c = nums_c.filter(x => x != '-')
      const start_c = Math.min.apply(null, nums_c)
      const end_c = Math.max.apply(null, nums_c)
      list['contrastRate'] = { start: start_c, end: end_c }
    }
    //同比
    if (contrastType && ['Last', 'LastAndCircle'].includes(contrastType)) {
      let nums_l = rows.map(r => {
        let val = r[alias + '_lastRate']
        if (String(val).includes('%')) {
          val = parseFloat(val.replace(/[,]/g, ''))
        }
        return val
      })
      nums_l = nums_l.filter(x => x != '-')
      const start_l = Math.min.apply(null, nums_l)
      const end_l = Math.max.apply(null, nums_l)
      list['lastRate'] = { start: start_l, end: end_l }
    }
    //环比
    if (contrastType && ['Circle', 'LastAndCircle'].includes(contrastType)) {
      let nums_c = rows.map(r => {
        let val = r[alias + '_circleRate']
        if (String(val).includes('%')) {
          val = parseFloat(val.replace(/[,]/g, ''))
        }
        return val
      })
      nums_c = nums_c.filter(x => x != '-')
      const start_c = Math.min.apply(null, nums_c)
      const end_c = Math.max.apply(null, nums_c)
      list['circleRate'] = { start: start_c, end: end_c }
    }

    return list
  },

  getFilterData2: (d, chart) => {
    let copyres = _.cloneDeep(d)
    let filterSet = (chart.settings && chart.settings.filterSet) || chart.reqObj.settings.filterSet
    const propsetGroup = chart.settings?.propSet?.groups ?? []
    // let funnelarr = ['funnel', 'table_more']
    // if (funnelarr.includes(chart.reqObj.mode)) {
    //   return d
    // }

    let groups = copyres.columns.filter(x => {
      return x.usage == 'Group'
    })
    let measures = copyres.columns.filter(x => {
      return x.usage == 'Measure'
    })
    let rows = copyres.rows.filter(x => {
      let s = ''
      let isUnknown = false
      groups.forEach((g, gidx) => {
        //隐藏未知
        if (filterSet && filterSet.hideUnknown) {
          if (x[g.alias] == '-') {
            isUnknown = true
          }
        }
        if (gidx < groups.length - 1 && groups.length > 1) {
          s += x[g.alias] + '[|]'
        } else {
          s += x[g.alias]
        }
      })
      x.sys_gen_key = s
      // 指标类型 PERCENTAGE_DIRECT 的值 *0.01
      if (!x.per_direct) {
        copyres.columns.forEach(m => {
          if (m.displayType == 'PERCENTAGE_DIRECT') {
            x[m.alias] = x[m.alias] * 0.01
          }
        })
        x.per_direct = true
      }
      return x && !isUnknown
    })
    let filterdata = {}
    if (copyres.columns.length > 0) {
      let oldname = ''
      filterdata.columns = copyres.columns.filter(x => {
        if (x.usage === 'Group') {
          const grp = propsetGroup.find(g => g.fullAlias === x.fullAlias)
          if (grp) x.title = grp.title
        }
        let targ =
          filterSet.columns &&
          filterSet.columns.find(t => {
            if (t.fullAlias == x.fullAlias) {
              oldname = x.title
            }
            return t.fullAlias == x.fullAlias
          })
        let isshow = targ && (!targ.usage || targ.usage === 'Measure') ? targ.selected : true
        x.title = targ && (!targ.usage || targ.usage === 'Measure') ? targ.name : initMarkV2.reviseName(copyres, x)
        if (!targ) {
          let meatarg =
            filterSet &&
            filterSet.columns &&
            filterSet.columns.find(t => {
              return x.alias.includes(t.alias)
            })
          if (meatarg) {
            x.title = x.title.replace(oldname, meatarg.name)
          }
        }
        return isshow
      })

      //指标数值过滤开启时，根据指标start，end过滤数值
      if (filterSet.filterValue) {
        rows = rows.filter(x => {
          let istrue = true
          filterSet.columns &&
            filterSet.columns.forEach(c => {
              let val =
                c.filterCode && x[c.filterCode]
                  ? typeof x[c.filterCode] === 'number'
                    ? x[c.filterCode]
                    : x[c.filterCode] == '-'
                    ? '-'
                    : parseFloat(x[c.filterCode].replace(/[,]/g, ''))
                  : x[c.alias]
              if ((c.start || c.start === 0) && (c.end || c.end === 0) && c.start <= c.end && val >= c.start && val <= c.end) {
              } else if ((c.start || c.start === 0) && (c.end || c.end === 0) && c.start > c.end && val >= c.start) {
              } else if (!c.start && c.start !== 0 && (c.end || c.end === 0) && val <= c.end) {
              } else if ((c.start || c.start === 0) && !c.end && c.end !== 0 && val >= c.start) {
              } else if (!c.start && c.start !== 0 && !c.end && c.end !== 0) {
              } else {
                istrue = false
              }
            })
          return istrue
        })
        filterdata.rows = rows
      } else {
        filterdata.rows = rows
      }

      // 漏斗
      if (filterdata.funnels) {
        let funnels = filterdata.funnels.map(x => {
          let itemres = mCommonJS.getFunnelSteps(x, chart.settings)
          return itemres
        })
        filterdata.funnels = funnels
      }
    } else {
      filterdata = copyres
      filterdata.rows = rows
    }
    if (copyres.timeRanges) {
      filterdata.timeRanges = copyres.timeRanges
    }
    if (copyres.uniqueID) {
      filterdata.uniqueID = copyres.uniqueID
    }
    // 如果分组名设置了不可见
    const groupformatlist = propsetGroup.filter(x => x.groupformat && x.groupformat.length > 0)
    if (groupformatlist) {
      filterdata.rows.forEach(x => {
        groupformatlist.forEach(g => {
          g.groupformat.forEach(f => {
            x[g.alias] = x[g.alias].replace(f, '')
          })
        })
      })
    }
    return filterdata
  },
  getFunnelSteps(searchRes, settings) {
    const copyColumns = JSON.parse(JSON.stringify(searchRes.columns))
    let res = searchRes
    const propsetGroup = settings?.propSet?.groups ?? []

    let columns = copyColumns.map(x => {
      if (x.usage === 'Group') {
        const grp = propsetGroup.find(g => g.fullAlias === x.fullAlias)
        if (grp) x.title = grp.title
      }
      return x
    })
    res.columns = columns
    return res
  },
}

export default mCommonJS
