import request from '@/utils/request2'

// 移动端调整位置
export function updateMobilePosition(data) {
  return request({
    url: '/dashboard/mobile/position',
    data: data,
    method: 'put',
  })
}
// 移动端卡片样式
export function updateMobileBookmark(data) {
  return request({
    url: '/dashboard/mobile/bookmark',
    data: data,
    method: 'put',
  })
}
// 移动端重新布局
export function updateMobileReset(data) {
  return request({
    url: '/dashboard/mobile/reset',
    data: data,
    method: 'put',
  })
}
