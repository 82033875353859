import Scheduler from '@/script/global/Scheduler.js'
import _ from 'lodash'
import store from '@/store'
import BI_config from '@/utils/config'
import bi_echarts from 'mallcoo_bi_echarts'
const Table = bi_echarts.Table
import { allColor } from '@/datajs/static.js'
import initMarkV2 from '@/script/bookmarkV2/initMarkV2'
import { Search2, getAdvanceSearch2, getMallMap } from '@/api/apiV2_dashboard'
import commonJs from '@/script/common.js'
import mCommonJS from './common'

let mobilejs = {
  getChartData(_this, bookmarks) {
    let chartData = []
    bookmarks.forEach((el, i) => {
      let chart = mobilejs.initchart(el, i)
      chartData.push(chart)
    })
    return chartData
  },
  getSearchData(_this, chartData) {
    let scheduler = new Scheduler(5, _this.$route.fullPath)
    chartData.forEach((el, i) => {
      if (el.canVisit) {
        mobilejs.searchRequest(_this, el, i, scheduler)
      } else {
        _this.originResults.push({ id: el.id, d: null })
      }
    })
    scheduler.start()
  },
  searchRequest(_this, el, i, scheduler) {
    if (scheduler) {
      scheduler.add(() => {
        return mobilejs.getSearch(_this, el, i)
      })
    }
  },
  async getSearch(_this, chart, idx, refresh, type) {
    let bookmark = _this.bookmarks[idx]
    let param = {
      id: chart.id,
    }
    if (refresh) {
      param.forceRefresh = true
    }
    if (type) {
      param.timeRanges = chart.timeRange
      param.contrastType = chart.reqObj.contrastType
    }
    const p = JSON.parse(bookmark.definition)
    let hotmapdata = ['Behavior', 'behavior'].includes(chart.searchCode) ? await initMarkV2.hotMap(_this, idx, p.parameter) : '1'
    mobilejs.rtSearch(_this, chart, idx, param, hotmapdata)
  },
  rtSearch(_this, el, idx, param, hotmapdata) {
    return Search2(param)
      .then(async d => {
        if (!d) {
          if (_this.originChartData[idx]) _this.originChartData[idx].loading = false
          return
        }
        if (!d.data) return

        if (el.searchCode === 'advance') {
          let coderes = ''
          if (d && d.code == '201') {
            coderes = await getAdvanceSearch2(d.data.requestID)
            if (coderes.code == '200') {
              mobilejs.searchEnd(_this, el, idx, coderes.data, hotmapdata)
            }
          } else if (d.code == '200') {
            mobilejs.searchEnd(_this, el, idx, d.data, hotmapdata)
          }
          if (coderes && coderes.code == '202') {
            let searchRes = ''
            searchRes = await getAdvanceSearch2(d.data.requestID)
            if (searchRes && searchRes.code == '202') {
              let searchtimes = 1
              mobilejs.recurrence(_this, el, searchRes, d, idx, hotmapdata, searchtimes)
            } else if (searchRes.code == '200') {
              d = searchRes
              mobilejs.searchEnd(_this, el, idx, d.data, hotmapdata)
            }
          } else if (coderes && coderes.code == '200') {
            d = coderes
            mobilejs.searchEnd(_this, el, idx, d.data, hotmapdata)
          }
        } else {
          mobilejs.searchEnd(_this, el, idx, d.data, hotmapdata)
        }
      })
      .catch(err => {
        console.error(err)
      })
  },
  async recurrence(_this, el, searchRes, d, idx, hotmapdata, searchtimes) {
    if (searchtimes >= 90) {
      _this.$alert(`查询超时`, '提示', {
        confirmButtonText: '确定',
      })
      return
    }
    try {
      searchRes = await getAdvanceSearch2(d.data.requestID)
    } catch (e) {
      if (_this.chartData[idx]) _this.chartData[idx].loading = false
    }
    if (searchRes && searchRes.code == '200') {
      d = searchRes
      mobilejs.searchEnd(_this, el, idx, d.data, hotmapdata)
    } else if (searchRes && searchRes.code == '202') {
      setTimeout(() => {
        searchtimes++
        return mobilejs.recurrence(_this, el, searchRes, d, idx, hotmapdata, searchtimes)
      }, 2000)
    }
  },
  searchEnd(_this, el, idx, d, hotmapdata) {
    _this.originResults.push({ id: el.id, d: d || null })
    if (_this.originChartData[idx]) {
      let option = null
      if (d.columns.length == 0 || d.rows.length == 0) {
        option = { noData: true }
        _this.$set(_this.originChartData[idx], 'option', option)
      } else {
        option = { noData: false }
        _this.$set(_this.originChartData[idx], 'option', option)
        let bookmark = _this.bookmarks[idx]
        let mobileBookmarks = _this.dashboard?.mobileSettings?.bookmarks ?? []
        const findMbookmark = mobileBookmarks.find(x => x.id === bookmark.id)
        const temp = bookmark.category.slice(0, 1).toUpperCase() + bookmark.category.slice(1)
        let p = JSON.parse(bookmark.definition)

        let bookmarksettings = findMbookmark && findMbookmark.settings && JSON.parse(findMbookmark.settings) ? JSON.parse(findMbookmark.settings) : p.settings
        // 如果没有名称设置 增加默认名称设置
        // 兼容早期卡片 没有 propSet ，从原始 columns 和 filterset 获取 分组和指标
        const propsets = bookmarksettings.propSet
        if (!propsets && d.columns.length > 0 && bookmarksettings.type !== 'table_more' && bookmarksettings.type !== 'funnel') {
          const groupColumns = d.columns.filter(x => x.usage === 'Group')
          const respropColumns = d.columns.filter(x => x.usage === 'Measure')
          if (!bookmarksettings.filterSet.columns) {
            bookmarksettings.filterSet.columns = []
          }
          const filterprops = bookmarksettings.filterSet.columns.filter(x => !x.usage || x.usage === 'Measure')
          const groups = groupColumns.map(x => {
            return {
              alias: x.alias,
              fullAlias: x.fullAlias,
              title: x.title,
            }
          })
          const props = filterprops.map(x => {
            const targ = respropColumns.find(t => t.fullAlias == x.fullAlias)
            const displayType = (targ && (targ.displayType || targ.dataType)) || ''
            const format = {
              thousands: true, // 千分位
              percent: targ && ['PERCENTAGE', 'PERCENTAGE_DIRECT', 'PERCENTAGE_INTERGER'].includes(displayType), // 百分比
              // 小数点位数,null 不做处理，0，1，2，3 是保留几位小数
              digit: ['PERCENTAGE', 'PERCENTAGE_DIRECT'].includes(displayType) ? 2 : displayType == 'PERCENTAGE_INTERGER' ? 0 : null,
            }
            return {
              alias: x.alias,
              fullAlias: x.fullAlias,
              title: x.name,
              format,
            }
          })

          const propsets = {
            groups,
            props,
          }
          bookmarksettings.propSet = propsets
        }
        // 如果图表没有Y轴样式设置 增加默认设置
        if (['line', 'bar', 'bar_line', 'scatter', 'bubble', 'biaxial_graph'].includes(bookmarksettings.type) && !bookmarksettings.styleSet.yAxisformat) {
          bookmarksettings.styleSet.yAxisNumUnit = 'none'
          bookmarksettings.styleSet.yAxisformat = { thousands: true, percent: false, digit: null }
        }
        if (temp === 'Template' && p.parameter.usage === 'Path' && bookmarksettings.type === 'table') {
          bookmarksettings.type = 'table_path'
        }
        _this.originChartData[idx]['settings'] = bookmarksettings

        let result = mCommonJS.getFilterData2(d, _this.originChartData[idx])
        let opts = {
          ...bookmarksettings,
          analysisType: temp,
        }
        let tableObj = new Table({
          opts,
          columns: result.columns,
          result: result.rows,
        })
        //Table 内部修改了rows 需要同步更新d1
        result.rows = tableObj.params.result
        const tableObjdata = tableObj.init()

        mCommonJS.initFilter(result, p)

        bookmarksettings = commonJs.compatibleHistory(bookmarksettings)
        let cardinfos = {
          index: idx,
          cardkey: String(idx),
          cardresizekey: String(idx),
          parameter: p.parameter,
          settings: bookmarksettings,
          result,
          hotmapdata,
          projectopts: {
            basicInfo: store.state.basicInfo,
            theme: store.state.basicInfo.project.theme,
            mainColor: store.state.basicInfo.project.mainColor,
            pageunit: store.state.basicInfo.project.axisUnit,
            contrastTrendColor: store.state.basicInfo.project.contrastTrendColor,
            projectMalls: store.state.projectMalls,
            weathers: store.state.weathers,
            festivals: store.state.festivals,
          },
          cardopts: {
            searchCode: el.searchCode,
            category: temp,
            usage: temp === 'Template' && p.parameter.usage,
          },
          // drillFilter, //钻取筛选条件
        }
        if (temp == 'Segment') {
          cardinfos.cardopts.uniqueID = result.uniqueID
          cardinfos.cardopts.segmentName = bookmarksettings.nameCN || bookmarksettings.nameEN
        }
        _this.$set(_this.originChartData[idx], 'cardinfos', cardinfos)
      }
      _this.originChartData[idx].loading = false
    }
  },
  gettimeRange(_this, em, iscontrast) {
    let emit = JSON.parse(JSON.stringify(em))
    if (emit) {
      let req = emit.item.reqObj
      _this.$set(em.item, 'timeRange', emit.v2time)
      let isRelative = emit.v2time[0].mode
      let timeRan = _this.$commonJs.getContrastTimeV2(_this, req, emit, isRelative, iscontrast)

      _this.$set(_this.chartData[emit.index], 'reqObj', req)
      _this.$set(_this.chartData[emit.index], 'timeRange', timeRan)
      let bookmark = _this.bookmarks[emit.index]
      if (bookmark.category === 'combination') {
        // cardinitMark.searchchange(_this, idx, contrastitem, type, scheduler)
      } else {
        mobilejs.searchchange(_this, emit.index, req)
      }
    }
  },
  searchchange(_this, idx, req, type, changeval) {
    let chart = _this.chartData[idx]
    let bookmark = _this.bookmarks.find(x => x.id === chart.id)
    let definition = JSON.parse(bookmark.definition)
    let temp = ''
    let p2 = {}
    temp = bookmark.category.slice(0, 1).toUpperCase() + bookmark.category.slice(1)
    p2 = definition.parameter
    chart.loading = true
    _this.$set(chart, 'option', null)
    let contrast = req.contrastType
    if (type == 'contrast') {
      contrast = changeval
      let v2item = {}
      v2item.v2time = chart.timeRange
      switch (contrast) {
        case 'nocontrast':
          contrast = 'Current'
          break
        case 'contrast':
          contrast = 'Contrast'
          var timeRa = chart.timeRange
          v2item.v2time = mobilejs.getV2timeRan(_this, chart, timeRa)
          break
        case 'last':
          contrast = 'Last'
          break
        case 'circle':
          contrast = 'Circle'
          break
        case 'lastAndCircle':
          contrast = 'LastAndCircle'
          break
      }
      let timeRan = mobilejs.getContrastTimeV2(_this, req, v2item, contrast)
      chart.timeRange = timeRan
      p2.contrastType = contrast
    } else if (type == 'slot') {
      switch (contrast) {
        case 'nocontrast':
          contrast = 'Current'
          break
        case 'contrast':
          contrast = 'Contrast'
          break
        case 'last':
          contrast = 'Last'
          break
        case 'circle':
          contrast = 'Circle'
          break
        case 'lastAndCircle':
          contrast = 'LastAndCircle'
          break
      }
      p2.contrastType = contrast
      chart.selectedGroups = [{ code: 'eventTime', type: 'date', category: 3, value: changeval, text: '事件发生时间' }]
    }
    _this.$set(chart, 'reqObj', req)
    mobilejs.getSearch2(_this, chart, idx, type, p2)
  },
  getSearch2(_this, chart, idx, type, p2) {
    let param = {
      id: chart.id,
    }
    if (p2.contrastType) {
      param.contrastType = p2.contrastType
    }
    if (type === 'refresh') {
      param.forceRefresh = true
    }
    if (chart.timeRange) {
      param.timeRanges = chart.timeRange
      p2.timeRanges = chart.timeRange
    }
    if (chart.selectedGroups) {
      param.groups = p2.groups
      param.groups.forEach(g => {
        if (g.code == 'time' && g.mode == 'Normal') {
          g.preset = chart.selectedGroups[0].value
          g.ranges = null
        }
      })
    }
    // 是否累计值
    const settings = chart?.cardinfos?.settings
    if (settings && settings.styleSet && settings.styleSet.allDimValues) {
      param.enableHistory = true
    }
    mobilejs.rtSearch2(_this, chart, idx, param, p2)
  },
  rtSearch2(_this, chart, idx, param, p2) {
    return Search2(param)
      .then(async d => {
        // _this.originResults.push({ id: chart.id, d: (d && d.data) || null })
        if (!d || !d.data) {
          if (_this.chartData[idx]) _this.chartData[idx].loading = false
          return
        }
        mobilejs.searchEnd2(_this, chart, idx, d.data, p2)
      })
      .catch(err => {
        console.error(err)
      })
  },
  searchEnd2(_this, chart, idx, d, p2) {
    let result = initMarkV2.getFilterData2(d, _this.chartData[idx])
    let option = null
    if (result.columns.length == 0 || result.rows.length == 0) {
      option = { noData: true }
    } else {
      option = { noData: false }
    }
    chart.option = option

    let cardinfos = chart.cardinfos
    cardinfos.parameter = p2
    cardinfos.result = result
    cardinfos.cardkey = cardinfos.cardkey + '1'
    chart.loading = false
  },

  initchart(el, i) {
    const definition = JSON.parse(el.definition)

    const path = el.category + '/' + store.state.projectID + '/' + el.id
    let url = BI_config.analysisUrl + BI_config.analysisProxy + '/' + path

    let reqObj = _.cloneDeep(definition)
    reqObj['setting'] = reqObj.settings
    reqObj.title = reqObj.settings.nameCN
    reqObj.mode = reqObj.settings.type

    reqObj.contrastType = reqObj.parameter.contrastType == 'Current' ? 'nocontrast' : reqObj.parameter.contrastType
    reqObj.contrastType = reqObj.contrastType ? reqObj.contrastType.toLowerCase() : ''

    let timeRange = reqObj.parameter.timeRanges
    if (el.category == 'template') {
      const timeComp = definition.parameter.components.find(x => {
        return x.type == 'TimePicker'
      })
      timeRange = timeComp ? [timeComp.timeRange] : null
    }
    //高级分析是否有时间
    const hastime_advance = el.category == 'advance' ? getadvancetime(reqObj.parameter) : true

    // 卡片主题设置 chartMainColor 为 0 跟随系统(store.state.basicInfo.project.mainColor) 否则（themeSet.chartMainColor）
    // 项目主题mainColor 0
    const themeSet = reqObj.setting.themeSet
    const chartMainColor = (themeSet && parseInt(themeSet.chartMainColor)) || 0
    const categoryColor = (themeSet && parseInt(themeSet.categoryColor)) || 0
    const mainColoridx = chartMainColor > 0 ? chartMainColor : store.state.basicInfo.project.mainColor
    const mainColor = chartMainColor > 0 ? allColor['color' + (mainColoridx - 1)] : allColor['color' + mainColoridx]

    let chart = {
      index: i,
      id: el.id,
      lock: el.lock,
      canVisit: el.canVisit,
      mapkey: i + '-',
      searchCode: el.category,
      loading: el.canVisit ? true : false,
      url: url,
      reqObj: reqObj,
      timeRange: timeRange && hastime_advance ? JSON.parse(JSON.stringify(timeRange)) : null,
      otherset: {},
      rdMainColor: chartMainColor,
      chartColorV2: mainColor[categoryColor],
      rdChartColor: categoryColor,
      option: '',
    }
    let getslot = []
    let slots = []
    if (reqObj.parameter.groups) {
      getslot = reqObj.parameter.groups.filter(g => {
        return g.code == 'time' && g.mode == 'Normal'
      })
      slots = getslot.length > 0 ? getslot[0].preset.split('_') : ''
    }
    chart.otherset.slot = getslot.length > 0 ? (slots.length > 1 ? slots[1] : slots[0]) : null
    return chart
  },
  getV2timeRan(_this, chart, timeRange) {
    let time = timeRange
    if (chart.timeRange) {
      time = chart.timeRange
    }
    let t1 = JSON.parse(JSON.stringify(time[0]))
    let t2 = {}
    if (t1.mode == 'Relative') {
      let showtime = commonJs.getshowdata(_this, t1)
      t2.start = showtime[0]
      t2.end = showtime[1]
      t2.mode = 'Absolute'
    } else {
      t2 = t1
    }
    const diff_days = _this.moment(t2.end).diff(_this.moment(t2.start), 'days')
    t2.end = _this
      .moment(t2.start)
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
    const date2 = _this
      .moment(t2.start)
      .subtract(diff_days + 1, 'days')
      .format('YYYY-MM-DD')
    t2.start = date2
    if (!time[1]) {
      time[1] = t2
    }
    return time
  },
  getContrastTimeV2(_this, req, emit, contrast) {
    //如果修改的是对比时间，获取当前时间参数并修改对比时间
    //否则获取时间参数 如果有对比 第二个时间不修改否则计算对比时间
    let timeRange = emit && emit.v2time ? emit.v2time : req.timeRange ? req.timeRange : req.parameter.timeRanges
    let times = JSON.parse(JSON.stringify(timeRange))
    let newTime = []
    newTime.push(times[0])
    if (contrast == 'Contrast') {
      newTime.push(times[1])
    }
    return newTime
  },
}

function getadvancetime(parameter) {
  let hastime = false
  parameter.analyses.forEach(x => {
    if (!hastime && x.category === 'behavior') {
      hastime = true
    }
    if (!hastime && x.category === 'template') {
      const components = JSON.parse(x.definition).parameter.components
      const timeRanges = components.find(c => {
        return c.type === 'TimePicker'
      })
      if (timeRanges) hastime = true
    }
  })

  return hastime
}
export default mobilejs
