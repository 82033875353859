<template>
  <div class="mobileset" :key="mobilekey">
    <mhead @pagesetchange="pagesetchange" @reset="reset" @empty="empty" />
    <div class="m-main">
      <div class="left-box">
        <preview
          class="previewbox"
          ref="previewref"
          :key="previewkey"
          v-if="dashboard && bookmarks && pageset"
          :dashboard="dashboard"
          :bookmarks="bookmarks"
          :originResults="originResults"
          :originChartData="originChartData"
          :style="{
            width: pageset.devicewh.w + 'px',
            height: pageset.devicewh.h + 'px',
            transform: `scale(${pageset.size / 100})`,
          }"
        />
      </div>
      <rightcardlist
        class="r-card-list"
        ref="rightcardlistref"
        :key="rightkey"
        v-if="dashboard && bookmarks"
        :chartData="originChartData"
        :dashboard="dashboard"
        :bookmarks="bookmarks"
        @addcard="addcard"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mhead from './components/head'
import preview from './preview'
import rightcardlist from './components/rightcardlist'
import mobilejs from './js/mobile'
import { getDashboard, getBookmarks } from '@/api/apiV2_dashboard'
import { updateMobilePosition } from '@/api/mobileapi.js'
export default {
  data() {
    return {
      dashboard: '',
      bookmarks: '',
      originResults: [], // 原始卡片数据
      originChartData: [], // 原始图表数据

      defaultLayout: true, //是否默认排版
      pageset: '',
      mobilekey: 0,
      rightkey: 0,
      previewkey: 0,
    }
  },
  computed: {
    ...mapState({
      system: state => state.system,
    }),
  },
  components: {
    mhead,
    preview,
    rightcardlist,
  },
  watch: {
    '$route.hash': function() {
      this.originResults = []
      this.originChartData = []
      this.dashboard = ''
      this.init('hash')
      this.mobilekey++
      this.previewkey++
    },
  },
  created() {
    this.pageset = { devicewh: { w: 390, h: 844 }, size: 100 }
    this.init()
  },
  methods: {
    async init(ischangehash) {
      let dashboardID = this.$route.query.dashboardID || this.$route.hash.replace('#/', '')
      if (!dashboardID) {
        return
      }
      this.$loading({ lock: true, spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' })
      await Promise.all([getDashboard(dashboardID), getBookmarks(this.system, dashboardID)]).then(values => {
        this.dashboard = values[0].data
        this.bookmarks = values[1].data.filter(x => x.category !== 'combination')
        this.originChartData = mobilejs.getChartData(this, this.bookmarks)
      })
      this.$loading({ lock: false }).close()
      this.$store.commit('setFullPath', this.$route.fullPath)
      mobilejs.getSearchData(this, this.originChartData)
      if (ischangehash) {
        this.rightkey++
      }
      // 是否默认排版 mobileSettings/bookmarks 为null 面板为默认排版， bookmarks 为空数组[] 面板为空
      if (!this.dashboard.mobileSettings || !this.dashboard.mobileSettings.bookmarks) {
        this.defaultLayout = true
      } else {
        this.defaultLayout = false
      }
    },
    addcard(item) {
      this.$refs['previewref'].addcard(item)
    },
    pagesetchange(item) {
      console.log(item)
      this.pageset = item
    },
    async reset() {
      let dashboardID = this.$route.query.dashboardID || this.$route.hash.replace('#/', '')
      try {
        getDashboard(dashboardID).then(v => {
          this.dashboard = v.data
          this.previewkey++
          this.$refs['rightcardlistref'].updatelist([])
        })
      } catch (err) {}
    },
    empty() {
      const param = {
        dashboardID: this.$route.hash.replace('#/', ''),
        bookmarks: [],
      }
      try {
        updateMobilePosition(param).then(v => {
          if (this.dashboard.mobileSettings && this.dashboard.mobileSettings.bookmarks) {
            this.dashboard.mobileSettings.bookmarks = []
            this.previewkey++

            this.$refs['rightcardlistref'].updatelist([])
          } else {
            this.previewkey++
            this.$refs['rightcardlistref'].updatelist([])
          }
        })
      } catch (err) {}
    },
  },
}
</script>

<style lang="scss" scoped>
.mobileset {
  .m-main {
    height: calc(100% - 75px);
    margin: 15px 15px 0;
    position: relative;
    .left-box {
      width: calc(100% - 380px);
      height: 100%;
      display: flex;
      justify-content: center;
      .previewbox {
        transform-origin: top;
      }
    }
    .r-card-list {
      width: 380px;
      height: 100%;
      background: #fff;
      position: absolute;
      top: 0;
      right: 0;
      border: 1px solid #e9ecef;
    }
  }
}
</style>
