const datajs = {
  allColor: {
    color0: [
      ['#3AA0FF', '#45BB44', '#FACC15', '#FF6054', '#3DC2C2', '#FF8500', '#8543E0', '#5B8EE6', '#9ADE62', '#D141D9', '#3D3FCD', '#A080E6', '#DDDDDD'],
      ['#0B2766', '#143A8C', '#1D50B3', '#286DD9', '#3490FF', '#40A9FF', '#69C0FF', '#91D5FF', '#BAE7FF ', '#E6F7FF'],
    ],
    color1: [
      ['#FF8500', '#81A650', '#F78CB2', '#505BDA', '#C36DCC', '#F1655A', '#76A7F5', '#53C4E8', '#A998CA', '#EDC160', '#8FCAD6', '#85CF37', '#DDDDDD'],
      ['#613400', '#874D00', '#AD6800', '#D47105', '#FF8500', '#FFA400', '#FBD667', '#FCE58F', '#FDF1B8', '#FEFBE6'],
    ],
    color2: [
      ['#5CDBD3', '#FF94FA', '#FAF464', '#94DBFF', '#939CFF', '#FF7D72', '#7DAFFF', '#B0DD73', '#FFA4C4', '#8EE1F2', '#9BE94A', '#C48EFF', '#DDDDDD'],
      ['#00232A', '#00474F', '#006D75', '#08979C', '#12C2C1', '#36CFC9', '#5CDBD3', '#87E8DE', '#B5F5EC', '#E6FFFB'],
    ],
    color3: [
      ['#F759AB', '#67C2E3', '#C4E272', '#3AA0FF', '#505BDA', '#F1655A', '#76A7F5', '#81A650', '#F78CB2', '#8FCAD6', '#85CF37', '#B37FEB', '#DDDDDD'],
      ['#520239', '#780650', '#9E1068', '#C41D7F', '#EB2F96', '#F759AB', '#FF85C0', '#FFADD2', '#FFD6E7', '#FFF0F6'],
    ],
    color4: [
      ['#B37FEB', '#F1655A', '#EDC160', '#53C4E8', '#505BDA', '#F1655A', '#76A7F5', '#81A650', '#F78CB2', '#8FCAD6', '#85CF37', '#3AA0FF', '#DDDDDD'],
      ['#120337', '#22065E', '#391085', '#531DAB', '#722ED1', '#9255DE', '#B37FEB', '#D3ADF7', '#EFDBFF', '#F9F0FF'],
    ],
  },
  // 手机型号
  mobileDevices: [
    {
      value: 'huaweip30',
      label: 'HUAWEI P30',
      size: {
        w: 360,
        h: 780,
      },
    },
    {
      value: 'iphone13',
      label: 'iPhone 13',
      size: {
        w: 390,
        h: 844,
      },
    },
    {
      value: 'iphone11',
      label: 'iPhone 11 Pro Max',
      size: {
        w: 414,
        h: 896,
      },
    },
    {
      value: 'ipad',
      label: 'iPad',
      size: {
        w: 768,
        h: 1024,
      },
    },
  ],
  // 画布尺寸
  pageSizes: [
    {
      value: 50,
      label: '50%',
    },
    {
      value: 60,
      label: '60%',
    },
    {
      value: 70,
      label: '70%',
    },
    {
      value: 80,
      label: '80%',
    },
    {
      value: 90,
      label: '90%',
    },
    {
      value: 100,
      label: '100%',
    },
    {
      value: 125,
      label: '125%',
    },
    {
      value: 150,
      label: '150%',
    },
  ],

  getValarr(contrastType, rows, alias) {
    let list = {}
    //指标值
    const nums = rows.map(r => {
      return r[alias]
    })
    const start = Math.min.apply(null, nums)
    const end = Math.max.apply(null, nums)
    list['current'] = { start: start, end: end }
    //对比
    if (contrastType && contrastType.includes('Contrast')) {
      let nums_c = rows.map(r => {
        let val = r[alias + '_contrastRate']
        if (String(val).includes('%')) {
          val = parseFloat(val.replace(/[,]/g, ''))
        }
        return val
      })
      nums_c = nums_c.filter(x => x != '-')
      const start_c = Math.min.apply(null, nums_c)
      const end_c = Math.max.apply(null, nums_c)
      list['contrastRate'] = { start: start_c, end: end_c }
    }
    //同比
    if (contrastType && ['Last', 'LastAndCircle'].includes(contrastType)) {
      let nums_l = rows.map(r => {
        let val = r[alias + '_lastRate']
        if (String(val).includes('%')) {
          val = parseFloat(val.replace(/[,]/g, ''))
        }
        return val
      })
      nums_l = nums_l.filter(x => x != '-')
      const start_l = Math.min.apply(null, nums_l)
      const end_l = Math.max.apply(null, nums_l)
      list['lastRate'] = { start: start_l, end: end_l }
    }
    //环比
    if (contrastType && ['Circle', 'LastAndCircle'].includes(contrastType)) {
      let nums_c = rows.map(r => {
        let val = r[alias + '_circleRate']
        if (String(val).includes('%')) {
          val = parseFloat(val.replace(/[,]/g, ''))
        }
        return val
      })
      nums_c = nums_c.filter(x => x != '-')
      const start_c = Math.min.apply(null, nums_c)
      const end_c = Math.max.apply(null, nums_c)
      list['circleRate'] = { start: start_c, end: end_c }
    }

    return list
  },
}

export default datajs
