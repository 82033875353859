// 图表设置相关展示数据
let checkDependency = (styleSet, type, code) => {
  var component = chartStyleSetList[type]
  if (component.dependency) {
    if (!component.dependency(styleSet, type)) return false
  }
  var item = component.flatMap(x => [...x.list]).find(x => x.code == code)
  if (item.dependency) {
    return item.dependency(styleSet, type)
  }
  return true
}

//图表汇总指标
let summaryField = {
  title: '汇总信息',
  rule: {
    groupCheck: len => len == 1,
    propCheck: len => len == 1,
    contrastCheck: contrastType => !contrastType || contrastType.toLowerCase() == 'current',
  },
  dependency: (styleSet, type) => {
    var code = 'allDimValues'
    return !Object.keys(styleSet).includes(code) || !styleSet[code]
  },
  list: [
    {
      code: 'aggregation',
      type: 'radio',
      label: '汇总指标',
      defaultValue: 'none',
      radios: [
        { value: 'sum', label: '总计' },
        { value: 'average', label: '平均值' },
        { value: 'median', label: '中位数' },
        { value: 'none', label: '不显示' },
      ],
    },
  ],
}
let dateExtendField = {
  code: 'enableDateExtend',
  rule: {
    fristGroupCheck: firstGroup => firstGroup && firstGroup.code == 'time' && firstGroup.dataType == 'Date' && firstGroup.preset == 'day',
    chartCheck: type => ['line', 'bar', 'bar_line', 'multiaxialBar', 'multiaxialLine', 'biaxial_graph'].includes(type),
  },
  dependency: (styleSet, type) => {
    if (!styleSet['showXAxis']) return false
    let code = 'direction'
    return (styleSet[code] && checkDependency(styleSet, type, code) && styleSet[code] == 'vertical') || !styleSet[code]
  },
  type: 'radio',
  label: '日期扩展',
  defaultValue: 'none',
  radios: [
    { value: 'none', label: '不显示' },
    { value: 'week', label: '显示星期' },
    { value: 'statutory', label: '显示法定节假日' },
    { value: 'custom', label: '显示自定义节假日' },
  ],
}
//单轴坐标轴
let optimizexAxisField = {
  title: '坐标轴',
  list: [
    {
      code: 'showXAxis',
      type: 'switch',
      label: 'X轴',
      defaultValue: true,
      radios: [
        { value: true, label: '显示' },
        { value: false, label: '不显示' },
      ],
    },
    {
      code: 'showXAxisTitle',
      dependency: (styleSet, type) => {
        var code = 'showXAxis'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      type: 'switch',
      label: 'X轴标题',
      defaultValue: false,
      radios: [
        { value: true, label: '显示' },
        { value: false, label: '不显示' },
      ],
    },
    {
      code: 'xAxisTitle',
      type: 'input',
      dependency: (styleSet, type) => {
        var code = 'showXAxisTitle'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      label: '',
    },
    {
      code: 'enableOptimizexAxis',
      dependency: (styleSet, type) => {
        var code = 'showXAxis'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      type: 'switch',
      label: '显示优化',
      defaultValue: true,
      radios: [
        { value: true, label: '开启' },
        { value: false, label: '关闭' },
      ],
    },
    dateExtendField,
    {
      code: 'showYAxis',
      type: 'switch',
      label: 'Y轴',
      defaultValue: true,
      radios: [
        { value: true, label: '显示' },
        { value: false, label: '不显示' },
      ],
    },
    {
      code: 'yAxisLine',
      type: 'switch',
      label: 'Y轴线',
      dependency: (styleSet, type) => {
        var code = 'showYAxis'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      defaultValue: true,
      radios: [
        { value: true, label: '显示' },
        { value: false, label: '不显示' },
      ],
    },
    {
      code: 'yAxisformat',
      type: 'yAxisformat',
      label: 'Y轴数字',
      defaultValue: {
        thousands: true,
        percent: false, // 百分比
        digit: null,
      },
    },
    {
      code: 'yAxisNumUnit',
      type: 'select',
      label: '计数单位',
      defaultValue: 'none',
      select: [
        { label: '跟随系统', value: 'none' },
        { label: '万', value: 'tenthousand' },
        { label: '百万', value: 'million' },
        { label: '亿', value: 'billion' },
        { label: 'K', value: 'thousand' },
        { label: 'M', value: 'trillion' },
      ],
    },
    {
      code: 'showYAxisTitle',
      dependency: (styleSet, type) => {
        var code = 'showYAxis'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      type: 'switch',
      label: 'Y轴标题',
      defaultValue: false,
      radios: [
        { value: true, label: '显示' },
        { value: false, label: '不显示' },
      ],
    },
    {
      code: 'yAxisTitle',
      type: 'input',
      dependency: (styleSet, type) => {
        var code = 'showYAxisTitle'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      label: '',
    },
    {
      code: 'yAxisUnit',
      dependency: (styleSet, type) => {
        var code = 'showYAxis'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      type: 'input',
      label: 'Y轴单位',
      maxLen: 3,
      placeholder: '单位长度不能超过3位',
    },
    // {
    //   code: 'yAxisUnit',
    //   type: 'input',
    //   dependency: (styleSet, type) => {
    //     var code = 'showYAxisTitle'
    //     return checkDependency(styleSet, type, code) && styleSet[code]
    //   },
    //   label: '单位',
    // },
    {
      code: 'areaYAxis',
      dependency: (styleSet, type) => {
        var code = 'showYAxis'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      type: 'areaYAxis',
      label: 'Y轴范围',
      defaultValue: ['', ''],
    },
    {
      code: 'gridLine',
      dependency: (styleSet, type) => {
        var code = 'showYAxis'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      type: 'select',
      label: '网格线',
      defaultValue: 'none',
      select: [
        { value: 'allLine', label: '全部显示' },
        { value: 'horizontalLine', label: '显示横线' },
        { value: 'verticalLine', label: '显示纵线' },
        { value: 'none', label: '不显示' },
      ],
    },
    {
      code: 'enableSlider',
      type: 'switch',
      label: '缩略轴',
      defaultValue: false,
      radios: [
        { value: true, label: '开启' },
        { value: false, label: '关闭' },
      ],
    },
  ],
}
//多轴坐标轴
let multiaoptimizexAxisField = {
  title: '坐标轴',
  list: [
    {
      code: 'showXAxis',
      type: 'switch',
      label: 'X轴',
      defaultValue: true,
      radios: [
        { value: true, label: '显示' },
        { value: false, label: '不显示' },
      ],
    },
    {
      code: 'enableOptimizexAxis',
      dependency: (styleSet, type) => {
        var code = 'showXAxis'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      type: 'switch',
      label: '显示优化',
      defaultValue: true,
      radios: [
        { value: true, label: '开启' },
        { value: false, label: '关闭' },
      ],
    },
    dateExtendField,
    {
      code: 'gridLine',
      type: 'select',
      label: '网格线',
      defaultValue: 'none',
      select: [
        { value: 'allLine', label: '全部显示' },
        { value: 'horizontalLine', label: '显示横线' },
        { value: 'verticalLine', label: '显示纵线' },
        { value: 'none', label: '不显示' },
      ],
    },
    {
      code: 'enableSlider',
      type: 'switch',
      label: '缩略轴',
      defaultValue: false,
      radios: [
        { value: true, label: '开启' },
        { value: false, label: '关闭' },
      ],
    },
  ],
}
let otherField = {
  title: '其他',
  list: [
    {
      code: 'enableWeather',
      type: 'switch',
      label: '城市天气',
      defaultValue: false,
      rule: {
        fristGroupCheck: firstGroup => firstGroup && firstGroup.code == 'time' && firstGroup.dataType == 'Date' && firstGroup.preset == 'day',
        chartCheck: type => ['line', 'bar', 'bar_line', 'multiaxialBar', 'multiaxialLine', 'biaxial_graph'].includes(type),
      },
      dependency: (styleSet, type) => {
        if (!styleSet['showXAxis']) return false
        let code = 'direction'
        return (styleSet[code] && checkDependency(styleSet, type, code) && styleSet[code] == 'vertical') || !styleSet[code]
      },
      radios: [
        { value: true, label: '显示' },
        { value: false, label: '不显示' },
      ],
    },
  ],
}

//图例
let legendField = {
  title: '图例',
  list: [
    {
      code: 'enableLegend',
      type: 'switch',
      label: '是否显示',
      defaultValue: false,
      radios: [
        { value: true, label: '是' },
        { value: false, label: '否' },
      ],
    },
    {
      code: 'enableLegendPosition',
      type: 'select',
      label: '显示位置',
      dependency: (styleSet, type) => {
        var code = 'enableLegend'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      defaultValue: 'centerOnBottom',
      select: [
        { value: 'centerOnBottom', label: '靠下居中' },
        { value: 'centerOnTop', label: '靠上居中' },
        { value: 'centerOnLeft', label: '靠左居中' },
        { value: 'centerOnRight', label: '靠右居中' },
      ],
    },
    {
      code: 'enableLegendType',
      type: 'radio',
      label: '展示方式',
      dependency: (styleSet, type) => {
        var code = 'enableLegend'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      defaultValue: 'scroll',
      radios: [
        { value: 'plain', label: '普通' },
        { value: 'scroll', label: '滚动翻页' },
      ],
    },
  ],
}

//堆积展示
let stackField = {
  code: 'enableStack',
  type: 'switch',
  rule: { groupCheck: len => len == 2 },
  label: '堆积展示',
  dependency: (styleSet, type) => {
    return !Object.keys(styleSet).includes('allDimValues') || !styleSet['allDimValues']
  },
  defaultValue: false,
  radios: [
    { value: true, label: '开启' },
    { value: false, label: '关闭' },
  ],
}

// 辅助线
let sublineField = {
  title: '辅助线',
  // rule: {
  //   contrastCheck: contrastType => !contrastType || contrastType.toLowerCase() == 'current',
  // },
  dependency: (styleSet, type) => {
    return !Object.keys(styleSet).includes('allDimValues') || !styleSet['allDimValues']
  },
  list: [
    {
      code: 'sublineradio',
      type: 'switch',
      label: '是否显示',
      defaultValue: false,
      radios: [
        { value: true, label: '是' },
        { value: false, label: '否' },
      ],
    },
    {
      code: 'subline',
      type: 'select',
      label: '',
      dependency: (styleSet, type) => {
        var code = 'sublineradio'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      defaultValue: 'fixed',
      select: [
        { value: 'fixed', label: '固定值' },
        { value: 'measureField', label: '图表字段' },
      ],
    },
    {
      code: 'sublineVal',
      type: 'input',
      label: '',
      dependency: (styleSet, type) => {
        var code = 'subline'
        return checkDependency(styleSet, type, code) && styleSet[code] == 'fixed'
      },
      input: '',
    },
    {
      code: 'sublineField',
      type: 'select',
      label: '',
      dependency: (styleSet, type) => {
        var code = 'subline'
        return checkDependency(styleSet, type, code) && styleSet[code] == 'measureField'
      },
      defaultValue: '',
      select: [],
    },
    {
      code: 'sublineAggregation',
      type: 'select',
      label: '',
      dependency: (styleSet, type) => {
        var code = 'subline'
        return checkDependency(styleSet, type, code) && styleSet[code] == 'measureField'
      },
      defaultValue: 'avg',
      select: [
        { value: 'min', label: '最小值' },
        { value: 'avg', label: '平均值' },
        { value: 'max', label: '最大值' },
        { value: 'med', label: '中位数' },
      ],
    },
  ],
}

let scatterSublineField = {
  title: '辅助线',
  // rule: {
  //   contrastCheck: contrastType => !contrastType || contrastType.toLowerCase() == 'current',
  // },
  list: [
    {
      code: 'sublineradio',
      type: 'switch',
      label: '是否显示',
      defaultValue: false,
      radios: [
        { value: true, label: '是' },
        { value: false, label: '否' },
      ],
    },
    {
      code: 'subline',
      type: 'select',
      label: '',
      dependency: (styleSet, type) => {
        var code = 'sublineradio'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      defaultValue: 'fixed',
      select: [
        { value: 'fixed', label: '固定值' },
        { value: 'average', label: '图表字段' },
      ],
    },
    {
      code: 'xAxisValue',
      type: 'input',
      label: 'X轴',
      dependency: (styleSet, type) => {
        var code = 'subline'
        return checkDependency(styleSet, type, code) && styleSet[code] == 'fixed'
      },
      input: '',
    },
    {
      code: 'yAxisValue',
      type: 'input',
      label: 'Y轴',
      dependency: (styleSet, type) => {
        var code = 'subline'
        return checkDependency(styleSet, type, code) && styleSet[code] == 'fixed'
      },
      input: '',
    },
    {
      code: 'xAxisSublineAggregation',
      type: 'select',
      label: 'X轴',
      dependency: (styleSet, type) => {
        var code = 'subline'
        return checkDependency(styleSet, type, code) && styleSet[code] == 'average'
      },
      defaultValue: 'avg',
      select: [
        { value: 'min', label: '最小值' },
        { value: 'avg', label: '平均值' },
        { value: 'max', label: '最大值' },
        { value: 'med', label: '中位数' },
      ],
    },
    {
      code: 'yAxisSublineAggregation',
      type: 'select',
      label: 'Y轴',
      dependency: (styleSet, type) => {
        var code = 'subline'
        return checkDependency(styleSet, type, code) && styleSet[code] == 'average'
      },
      defaultValue: 'avg',
      select: [
        { value: 'min', label: '最小值' },
        { value: 'avg', label: '平均值' },
        { value: 'max', label: '最大值' },
        { value: 'med', label: '中位数' },
      ],
    },
  ],
}

let dataCompressSet = [
  {
    code: 'enableCompress',
    type: 'switch',
    label: '其它',
    desc: '搭建的图表中若同一分组中的部分数值小于等于一定量时，统一归入"其它"分组中显示。',
    defaultValue: false,
    radios: [
      { value: true, label: '显示' },
      { value: false, label: '不显示' },
    ],
    rule: {
      groupCheck: len => len == 1,
      propCheck: len => len == 1,
      chartCheck: type => ['table', 'pie', 'line', 'bar', 'wordcloud', 'treemap'].includes(type),
    },
  },
  {
    code: 'compressWay',
    type: 'select',
    label: '',
    defaultValue: 'percentage',
    select: [
      { value: 'number', label: '按数值' },
      { value: 'percentage', label: '按百分比' },
      { value: 'top', label: '按TOP排序' },
    ],
    dependency: (styleSet, type) => {
      var code = 'enableCompress'
      return styleSet[code] && checkDependency(styleSet, type, code)
    },
  },
  {
    code: 'criticalValue',
    type: 'input',
    label: '',
    defaultValue: '10',
    dependency: (styleSet, type) => {
      var code = 'enableCompress'
      return styleSet[code] && checkDependency(styleSet, type, code)
    },
  },
]

// 标记
let enableLables = {
  title: '标记',
  list: [
    {
      code: 'enableLable',
      type: 'switch',
      label: '是否显示',
      defaultValue: false,
      radios: [
        { value: true, label: '是' },
        { value: false, label: '否' },
      ],
    },
    {
      code: 'numberunit',
      type: 'select',
      label: '计数单位',
      defaultValue: 'none',
      dependency: (styleSet, type) => {
        var code = 'enableLable'
        return checkDependency(styleSet, type, code) && styleSet[code]
      },
      select: [
        { label: '跟随系统', value: 'none' },
        { label: '万', value: 'tenthousand' },
        { label: '百万', value: 'million' },
        { label: '亿', value: 'billion' },
        { label: 'K', value: 'thousand' },
        { label: 'M', value: 'trillion' },
      ],
    },
  ],
}

let chartStyleSetList = {
  table: [
    {
      title: '表格设置',
      list: [
        {
          code: 'enablePivot',
          type: 'radio',
          label: '行列转置',
          defaultValue: 'closed',
          radios: [
            { value: 'full', label: '开启' },
            {
              value: 'part',
              label: '部分开启',
              rule: { groupCheck: len => len >= 2 },
            },
            { value: 'closed', label: '关闭' },
          ],
        },
        {
          code: 'enableConflation',
          rule: { groupCheck: len => len >= 1 },
          type: 'switch',
          label: '合并单元格',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'enablePaging',
          dependency: (styleSet, type) => {
            var code = 'enablePivot'
            var code2 = 'enableConflation'
            return checkDependency(styleSet, type, code) && styleSet[code] == 'closed' && checkDependency(styleSet, type, code2) && !styleSet[code2]
          },
          type: 'switch',
          label: '分页',
          defaultValue: true,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'enableRownumber',
          type: 'switch',
          label: '行号',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'aggregation',
          rule: { groupCheck: len => len >= 1 },
          type: 'radio',
          label: '汇总指标',
          defaultValue: 'none',
          radios: [
            { value: 'none', label: '不显示' },
            { value: 'sum', label: '总计' },
            { value: 'average', label: '平均值' },
            { value: 'median', label: '中位数' },
            { value: 'averageExt', label: '去零平均值' },
          ],
        },
        {
          code: 'enableComparison',
          rule: { contrastCheck: contrastType => contrastType && contrastType.toLowerCase() != 'current' },
          type: 'switch',
          label: '对比率',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'comparisonValue',
          dependency: (styleSet, type) => {
            var code = 'enableComparison'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'switch',
          label: '对比值',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'enableOccupation',
          type: 'switch',
          label: '占比率',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'compareMethod',
          dependency: (styleSet, type) => {
            var code = 'enableOccupation'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'radio',
          label: '占比方式',
          defaultValue: true,
          radios: [
            { value: true, label: '整体占比', desc: '整体占比，即每一个分组的数值（作为”分子“）在所有数值中（作为 ” 分母“）的占比情况。' },
            { value: false, label: '分组占比', desc: '分组占比，即每一个分组的数值（作为”分子“）在上一级分组中（作为 ” 分母“）的占比情况。' },
          ],
        },
        {
          code: 'isOccupationMerge',
          dependency: (styleSet, type) => {
            var code = 'enableOccupation'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'radio',
          label: '',
          defaultValue: true,
          radios: [
            { value: true, label: '合并显示' },
            { value: false, label: '单独显示' },
          ],
        },
        // {
        //   code: 'allDimValues',
        //   rule: {
        //     behaviorType: x => x === 'behavior',
        //     fristGroupCheck: firstGroup =>
        //       firstGroup && firstGroup.dataType == 'Date' && firstGroup.code == 'time' && ['hour', 'day', 'week', 'month', 'year'].includes(firstGroup.preset),
        //     pagingIsNull: paging => !paging,
        //   },
        //   type: 'switch',
        //   label: '累计值',
        //   defaultValue: false,
        //   radios: [
        //     { value: true, label: '开启' },
        //     { value: false, label: '关闭' },
        //   ],
        // },
        ...dataCompressSet,
      ],
    },
  ],
  table_path: [
    {
      title: '表格设置',
      list: [
        {
          code: 'enablePivot',
          type: 'radio',
          label: '行列转置',
          defaultValue: 'closed',
          radios: [
            { value: 'full', label: '开启' },
            {
              value: 'part',
              label: '部分开启',
              rule: { groupCheck: len => len >= 2 },
            },
            { value: 'closed', label: '关闭' },
          ],
        },
        {
          code: 'enablePaging',
          dependency: (styleSet, type) => {
            var code = 'enablePivot'
            return checkDependency(styleSet, type, code) && styleSet[code] == 'closed'
          },
          type: 'switch',
          label: '分页',
          defaultValue: true,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
      ],
    },
  ],
  table_more: [
    {
      title: '多表表格设置',
      list: [
        {
          code: 'enablePaging',
          type: 'switch',
          label: '分页',
          defaultValue: true,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'enableRownumber',
          type: 'switch',
          label: '行号',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
      ],
    },
  ],
  table_hotmap: [
    {
      title: '表格热力图设置',
      list: [
        {
          code: 'enablePaging',
          type: 'switch',
          label: '分页',
          defaultValue: true,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'enableTableRate',
          type: 'switch',
          label: '占比展示',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
      ],
    },
    summaryField,
  ],
  card: [
    {
      title: '数值',
      list: [
        {
          code: 'numberFontSize',
          type: 'select',
          label: '字号',
          defaultValue: 'auto',
          get select() {
            var fonts = [{ label: '自动', value: 'auto' }]
            var sizes = [16, 18, 20, 22, 24, 26, 28, 36, 48, 72]
            sizes.forEach(size => {
              fonts.push({ label: size, value: `${size}px` })
            })
            return fonts
          },
        },
        // {
        //   code: 'enableThousands',
        //   type: 'radio',
        //   label: '千分位',
        //   defaultValue: true,
        //   radios: [
        //     { value: true, label: '显示' },
        //     { value: false, label: '不显示' },
        //   ],
        // },
        // {
        //   code: 'enableDecimal',
        //   type: 'radio',
        //   label: '小数',
        //   defaultValue: false,
        //   radios: [
        //     { value: true, label: '显示' },
        //     { value: false, label: '不显示' },
        //   ],
        // },
        {
          code: 'numberunit',
          type: 'select',
          label: '计数单位',
          defaultValue: 'none',
          get select() {
            let fonts = [
              { label: '不显示', value: 'none' },
              { label: '万', value: 'tenthousand' },
              { label: '百万', value: 'million' },
              { label: '亿', value: 'billion' },
              { label: 'K', value: 'thousand' },
              { label: 'M', value: 'trillion' },
            ]
            return fonts
          },
        },
        {
          code: 'unitName',
          type: 'input',
          label: '指标单位',
          input: '',
          defaultValue: '',
        },
      ],
    },
    {
      title: '指标',
      list: [
        {
          code: 'showMeasure',
          type: 'switch',
          label: '指标名称',
          defaultValue: false,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'measureName',
          dependency: (styleSet, type) => {
            var code = 'showMeasure'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'input',
          label: '',
          input: '',
        },
        {
          code: 'measureNameFontSize',
          dependency: (styleSet, type) => {
            var code = 'showMeasure'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'select',
          label: '字号',
          defaultValue: 'auto',
          get select() {
            var fonts = [{ label: '自动', value: 'auto' }]
            var sizes = [16, 18, 20, 22, 24, 26, 28, 36, 48, 72]
            sizes.forEach(size => {
              fonts.push({ label: size, value: `${size}px` })
            })
            return fonts
          },
        },
      ],
    },
  ],
  pie: [
    {
      title: '饼图显示',
      list: [
        {
          code: 'type',
          type: 'radio',
          label: '形状设置',
          defaultValue: 'pie',
          radios: [
            { value: 'pie', label: '饼图' },
            { value: 'ring', label: '环图' },
            { value: 'rose', label: '南丁格尔玫瑰图' },
          ],
        },
        {
          code: 'roseType',
          type: 'radio',
          dependency: (styleSet, type) => {
            var code = 'type'
            return checkDependency(styleSet, type, code) && styleSet[code] == 'rose'
          },
          label: '显示模式',
          defaultValue: 'radius',
          radios: [
            { value: 'radius', label: '半径模式' },
            { value: 'area', label: '面积模式' },
          ],
        },
        {
          code: 'voidRatio',
          type: 'slider',
          dependency: (styleSet, type) => {
            var code = 'type'
            return checkDependency(styleSet, type, code) && (styleSet[code] == 'ring' || styleSet[code] == 'rose')
          },
          label: '空心占比',
          defaultValue: 30,
          slider: 0,
          defaultMin: 30,
          defaultMax: 90,
        },
        {
          code: 'centerPosition',
          type: 'slider',
          label: '中心位置',
          defaultValue: 50,
          slider: 0,
          defaultMin: 30,
          defaultMax: 70,
        },
        ...dataCompressSet,
      ],
    },
    {
      title: '标记',
      list: [
        {
          code: 'enableLable',
          type: 'switch',
          label: '分组名称',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'showNumber',
          type: 'switch',
          label: '数值',
          defaultValue: true,
          radios: [
            { value: true, label: '显示 ' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'enablePencentage',
          type: 'switch',
          label: '百分比',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'showGuideLine',
          type: 'switch',
          label: '引导线',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'numberunit',
          type: 'select',
          label: '计数单位',
          defaultValue: 'none',
          select: [
            { label: '跟随系统', value: 'none' },
            { label: '万', value: 'tenthousand' },
            { label: '百万', value: 'million' },
            { label: '亿', value: 'billion' },
            { label: 'K', value: 'thousand' },
            { label: 'M', value: 'trillion' },
          ],
        },
      ],
    },
    summaryField,
    legendField,
  ],
  line: [
    {
      title: '线图显示',
      list: [
        {
          code: 'lineType',
          type: 'radio',
          label: '图表样式',
          defaultValue: 'line',
          radios: [
            { value: 'line', label: '直线' },
            { value: 'areaLine', label: '面积直线' },
            { value: 'curve', label: '曲线' },
            { value: 'areaCurve', label: '面积曲线' },
          ],
        },
        {
          code: 'enableStack',
          type: 'switch',
          rule: { groupCheck: len => len == 2, propCheck: len => len == 1 },
          label: '堆积展示',
          dependency: (styleSet, type) => {
            return !Object.keys(styleSet).includes('allDimValues') || !styleSet['allDimValues']
          },
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'enablePencentage',
          dependency: (styleSet, type) => {
            var code = 'enableStack'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'switch',
          label: '百分比',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'showDataPoint',
          type: 'switch',
          label: '数据点',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },

        // {
        //   code: 'allDimValues',
        //   type: 'switch',
        //   label: '累计线',
        //   dependency: (styleSet, type) => {
        //     return !Object.keys(styleSet).includes('enableStack') || !styleSet['enableStack']
        //   },
        //   rule: {
        //     behaviorType: x => x === 'behavior',
        //     fristGroupCheck: firstGroup =>
        //       firstGroup && firstGroup.dataType == 'Date' && firstGroup.code == 'time' && ['hour', 'day', 'week', 'month', 'year'].includes(firstGroup.preset),
        //     pagingIsNull: paging => !paging,
        //   },
        //   defaultValue: false,
        //   radios: [
        //     { value: true, label: '开启' },
        //     { value: false, label: '关闭' },
        //   ],
        // },
        {
          code: 'summaryLine',
          type: 'radio',
          dependency: (styleSet, type) => {
            return !Object.keys(styleSet).includes('allDimValues') || !styleSet['allDimValues']
          },
          label: '汇总线',
          rule: { groupCheck: len => len >= 2 },
          defaultValue: 'none',
          radios: [
            { value: 'sum', label: '总计走势线' },
            { value: 'avg', label: '平均值线' },
            { value: 'none', label: '不显示' },
          ],
        },
        {
          code: 'contrastratio',
          type: 'switch',
          label: '对比率',
          dependency: (styleSet, type) => {
            return !Object.keys(styleSet).includes('allDimValues') || !styleSet['allDimValues']
          },
          rule: {
            contrastCheck: contrastType => contrastType && contrastType.toLowerCase() != 'current',
          },
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'comparisonValue',
          dependency: (styleSet, type) => {
            var code = 'contrastratio'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'switch',
          label: '对比值',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        ...dataCompressSet,
      ],
    },

    optimizexAxisField,
    sublineField,
    enableLables,
    legendField,
    summaryField,
    otherField,
  ],
  bar: [
    {
      title: '柱图显示',
      list: [
        {
          code: 'direction',
          type: 'radio',
          label: '图表样式',
          dependency: (styleSet, type) => {
            return !styleSet['contrastratio']
          },
          defaultValue: 'vertical',
          radios: [
            { value: 'horizontal', label: '横向' },
            { value: 'vertical', label: '竖向' },
          ],
        },
        stackField,
        {
          code: 'enablePencentage',
          dependency: (styleSet, type) => {
            var code = 'enableStack'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'switch',
          label: '百分比',
          rule: { groupCheck: len => len >= 2 },
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'contrastratio',
          type: 'switch',
          label: '对比率',
          dependency: (styleSet, type) => {
            return (!Object.keys(styleSet).includes('allDimValues') || !styleSet['allDimValues']) && styleSet['direction'] !== 'horizontal'
          },
          rule: {
            contrastCheck: contrastType => contrastType && contrastType.toLowerCase() != 'current',
          },
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'comparisonValue',
          dependency: (styleSet, type) => {
            var code = 'contrastratio'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'switch',
          label: '对比值',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        ...dataCompressSet,
      ],
    },
    optimizexAxisField,
    sublineField,
    enableLables,
    legendField,
    summaryField,
    otherField,
  ],
  bar_line: [
    {
      title: '柱图+线图设置',
      list: [
        {
          code: 'lineprops',
          type: 'selectMultiple',
          label: '线图指标',
          defaultValue: [],
          selectMultiple: [],
        },
        {
          code: 'showDataPoint',
          type: 'switch',
          label: '数据点',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        stackField,
        {
          code: 'enablePencentage',
          dependency: (styleSet, type) => {
            var code = 'enableStack'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'switch',
          label: '百分比',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
      ],
    },
    optimizexAxisField,
    sublineField,
    enableLables,
    legendField,
    summaryField,
    otherField,
  ],
  biaxial_graph: [
    {
      title: 'Y轴设置',
      list: [
        {
          code: 'lineprops',
          type: 'selectMultiple',
          label: '展示指标',
          defaultValue: [],
          selectMultiple: [],
        },
        {
          code: 'lineType',
          type: 'radio',
          label: '图表类型',
          defaultValue: 'bar',
          radios: [
            { value: 'bar', label: '柱图' },
            { value: 'line', label: '折线' },
            { value: 'areaLine', label: '面积折线' },
            { value: 'curve', label: '曲线' },
            { value: 'areaCurve', label: '面积曲线' },
          ],
        },
        {
          code: 'showDataPoint',
          type: 'switch',
          label: '数据点',
          defaultValue: true,
          dependency: (styleSet, type) => {
            var code = 'lineType'
            return checkDependency(styleSet, type, code) && styleSet[code] !== 'bar'
          },
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'enableStack',
          type: 'switch',
          rule: { groupCheck: len => len == 2 },
          label: '堆积展示',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        {
          code: 'showYAxis',
          type: 'switch',
          label: 'Y轴',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'yAxisLine',
          type: 'switch',
          label: 'Y轴线',
          dependency: (styleSet, type) => {
            var code = 'showYAxis'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'yAxisformat',
          type: 'yAxisformat',
          label: 'Y轴数字',
          defaultValue: {
            thousands: true,
            percent: false, // 百分比
            digit: null,
          },
        },
        {
          code: 'yAxisNumUnit',
          type: 'select',
          label: '计数单位',
          defaultValue: 'none',
          select: [
            { label: '跟随系统', value: 'none' },
            { label: '万', value: 'tenthousand' },
            { label: '百万', value: 'million' },
            { label: '亿', value: 'billion' },
            { label: 'K', value: 'thousand' },
            { label: 'M', value: 'trillion' },
          ],
        },
        {
          code: 'showYAxisTitle',
          dependency: (styleSet, type) => {
            var code = 'showYAxis'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'switch',
          label: 'Y轴标题',
          defaultValue: false,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'yAxisTitle',
          type: 'input',
          dependency: (styleSet, type) => {
            var code = 'showYAxisTitle'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          label: '',
        },
        {
          code: 'yAxisUnit',
          dependency: (styleSet, type) => {
            var code = 'showYAxis'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'input',
          label: 'Y轴单位',
          maxLen: 3,
          placeholder: '单位长度不能超过3位',
        },
        {
          code: 'areaYAxis',
          dependency: (styleSet, type) => {
            var code = 'showYAxis'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'areaYAxis',
          label: 'Y轴范围',
          defaultValue: ['', ''],
        },
      ],
    },
    {
      title: '次Y轴设置',
      list: [
        {
          code: 'nextLineprops',
          type: 'selectMultiple',
          label: '展示指标',
          defaultValue: [],
          selectMultiple: [],
        },
        {
          code: 'nextLineType',
          type: 'radio',
          label: '图表类型',
          defaultValue: 'areaCurve',
          radios: [
            { value: 'bar', label: '柱图' },
            { value: 'line', label: '折线' },
            { value: 'areaLine', label: '面积折线' },
            { value: 'curve', label: '曲线' },
            { value: 'areaCurve', label: '面积曲线' },
          ],
        },
        {
          code: 'shownextDataPoint',
          type: 'switch',
          label: '数据点',
          defaultValue: true,
          dependency: (styleSet, type) => {
            var code = 'nextLineType'
            return checkDependency(styleSet, type, code) && styleSet[code] !== 'bar'
          },
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        // {
        //   code: 'enableStack',
        //   type: 'radio',
        //   rule: { groupCheck: len => len == 2 },
        //   label: '堆积展示',
        //   defaultValue: false,
        //   radios: [
        //     { value: true, label: '开启' },
        //     { value: false, label: '关闭' },
        //   ],
        // },
        {
          code: 'shownextYAxis',
          type: 'switch',
          label: '次Y轴',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'nextYAxisLine',
          type: 'switch',
          label: '次Y轴线',
          dependency: (styleSet, type) => {
            var code = 'shownextYAxis'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'yAxisnextformat',
          type: 'yAxisformat',
          label: '次Y轴数字',
          defaultValue: {
            thousands: true,
            percent: false, // 百分比
            digit: null,
          },
        },
        {
          code: 'yAxisnextNumUnit',
          type: 'select',
          label: '计数单位',
          defaultValue: 'none',
          select: [
            { label: '跟随系统', value: 'none' },
            { label: '万', value: 'tenthousand' },
            { label: '百万', value: 'million' },
            { label: '亿', value: 'billion' },
            { label: 'K', value: 'thousand' },
            { label: 'M', value: 'trillion' },
          ],
        },
        {
          code: 'shownextYAxisTitle',
          dependency: (styleSet, type) => {
            var code = 'shownextYAxis'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'switch',
          label: '次Y轴标题',
          defaultValue: false,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'nextYAxisTitle',
          type: 'input',
          dependency: (styleSet, type) => {
            var code = 'shownextYAxisTitle'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          label: '',
        },
        {
          code: 'nextYAxisUnit',
          dependency: (styleSet, type) => {
            var code = 'shownextYAxis'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'input',
          label: '次Y轴单位',
          maxLen: 3,
          placeholder: '单位长度不能超过3位',
        },
        {
          code: 'nextareaYAxis',
          dependency: (styleSet, type) => {
            var code = 'shownextYAxis'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'areaYAxis',
          label: '次Y轴范围',
          defaultValue: ['', ''],
        },
      ],
    },
    {
      title: 'X轴',
      list: [
        {
          code: 'showXAxis',
          type: 'switch',
          label: '是否显示',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'showXAxisTitle',
          dependency: (styleSet, type) => {
            var code = 'showXAxis'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'switch',
          label: 'X轴标题',
          defaultValue: false,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'xAxisTitle',
          type: 'input',
          dependency: (styleSet, type) => {
            var code = 'showXAxisTitle'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          label: '',
        },
        {
          code: 'enableOptimizexAxis',
          dependency: (styleSet, type) => {
            var code = 'showXAxis'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'switch',
          label: '显示优化',
          defaultValue: true,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
        dateExtendField,
        {
          code: 'gridLine',
          dependency: (styleSet, type) => {
            var code = 'showYAxis'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          type: 'select',
          label: '网格线',
          defaultValue: 'none',
          select: [
            { value: 'allLine', label: '全部显示' },
            { value: 'horizontalLine', label: '显示横线' },
            { value: 'verticalLine', label: '显示纵线' },
            { value: 'none', label: '不显示' },
          ],
        },
        {
          code: 'enableSlider',
          type: 'switch',
          label: '缩略轴',
          defaultValue: false,
          radios: [
            { value: true, label: '开启' },
            { value: false, label: '关闭' },
          ],
        },
      ],
    },
    sublineField,
    enableLables,
    legendField,
    summaryField,
    otherField,
  ],
  multiaxialLine: [multiaoptimizexAxisField, enableLables, legendField, otherField],
  multiaxialBar: [multiaoptimizexAxisField, enableLables, legendField, otherField],
  radar: [
    {
      title: '雷达图设置',
      list: [
        {
          code: 'type',
          type: 'radio',
          label: '图表样式',
          defaultValue: 'line',
          radios: [
            { value: 'line', label: '线图' },
            { value: 'area', label: '面积图' },
          ],
        },
      ],
    },
    enableLables,
  ],
  funnel: [legendField],
  scatter: [optimizexAxisField, scatterSublineField],
  gauge: [
    {
      title: '计量图显示',
      list: [
        {
          code: 'tag',
          type: 'switch',
          label: '指标名称',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'showNumber',
          type: 'switch',
          label: '数值',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'enablePencentage',
          type: 'switch',
          label: '百分比',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'target',
          type: 'numberinput',
          label: '目标值',
          input: '',
        },
        {
          code: 'showTarget',
          type: 'switch',
          label: '显示目标值',
          defaultValue: false,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
      ],
    },
  ],
  bubble: [optimizexAxisField, scatterSublineField],
  relation: [
    {
      title: '关系图设置',
      list: [
        {
          code: 'incidNumber',
          type: 'select',
          label: '关联数',
          defaultValue: 'incid_5',
          select: [
            { value: 'incid_3', label: '3' },
            { value: 'incid_4', label: '4' },
            { value: 'incid_5', label: '5' },
            { value: 'incid_6', label: '6' },
            { value: 'incid_7', label: '7' },
            { value: 'incid_8', label: '8' },
            { value: 'incid_9', label: '9' },
            { value: 'incid_10', label: '10' },
            { value: 'incid_15', label: '15' },
          ],
        },
      ],
    },
  ],
  sankey: [enableLables],
  map: [
    {
      title: '标记',
      list: [
        {
          code: 'enableLable',
          type: 'switch',
          label: '店铺名',
          defaultValue: false,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'enableMax',
          type: 'select',
          label: '最大值',
          defaultValue: 'auto',
          select: [
            { value: 'auto', label: '自动' },
            { value: 'fixed', label: '固定' },
          ],
        },
        {
          code: 'enableFixed',
          type: 'input',
          dependency: (styleSet, type) => {
            var code = 'enableMax'
            return checkDependency(styleSet, type, code) && styleSet[code] == 'fixed'
          },
          label: '',
          defaultValue: '',
        },
        {
          code: 'enableUnit',
          type: 'input',
          label: '指标单位',
          defaultValue: '',
        },
      ],
    },
  ],
  wordcloud: [
    {
      title: '词云设置',
      list: [
        {
          code: 'numberFontSize',
          type: 'select',
          label: '最大字号',
          defaultValue: 'auto',
          get select() {
            var fonts = [{ label: '自动', value: 'auto' }]
            var sizes = [16, 18, 20, 22, 24, 26, 28, 36, 48, 72]
            sizes.forEach(size => {
              fonts.push({ label: size, value: `${size}px` })
            })
            return fonts
          },
        },
        {
          code: 'isRotation',
          type: 'switch',
          label: '是否旋转',
          defaultValue: false,
          radios: [
            { value: true, label: '是' },
            { value: false, label: '否' },
          ],
        },
        ...dataCompressSet,
      ],
    },
    summaryField,
  ],
  treemap: [
    {
      title: '矩形树图设置',
      list: [
        {
          code: 'enableLable',
          type: 'switch',
          label: '分组名称',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'showNumber',
          type: 'switch',
          label: '数值',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'enablePencentage',
          type: 'switch',
          label: '百分比',
          defaultValue: false,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        ...dataCompressSet,
      ],
    },
    summaryField,
  ],
  map3d: [
    {
      title: '3D地图设置',
      list: [],
    },
  ],
  sunburst: [
    {
      title: '旭日图显示',
      list: [
        {
          code: 'type',
          type: 'radio',
          label: '形状设置',
          defaultValue: 'pie',
          radios: [
            { value: 'pie', label: '饼图' },
            { value: 'ring', label: '环图' },
          ],
        },
        {
          code: 'voidRatio',
          type: 'slider',
          dependency: (styleSet, type) => {
            var code = 'type'
            return checkDependency(styleSet, type, code) && styleSet[code] == 'ring'
          },
          label: '空心占比',
          defaultValue: 30,
          slider: 0,
          defaultMin: 30,
          defaultMax: 90,
        },
        ...dataCompressSet,
      ],
    },
    {
      title: '标记',
      list: [
        {
          code: 'enableLable',
          type: 'switch',
          label: '分组名称',
          defaultValue: true,
          radios: [
            { value: true, label: '是' },
            { value: false, label: '否' },
          ],
        },
        {
          code: 'showNumber',
          type: 'switch',
          label: '数值',
          defaultValue: true,
          radios: [
            { value: true, label: '是' },
            { value: false, label: '否' },
          ],
        },
        {
          code: 'numberunit',
          type: 'select',
          label: '计数单位',
          defaultValue: 'none',
          dependency: (styleSet, type) => {
            var code = 'showNumber'
            return checkDependency(styleSet, type, code) && styleSet[code]
          },
          select: [
            { label: '跟随系统', value: 'none' },
            { label: '万', value: 'tenthousand' },
            { label: '百万', value: 'million' },
            { label: '亿', value: 'billion' },
            { label: 'K', value: 'thousand' },
            { label: 'M', value: 'trillion' },
          ],
        },
      ],
    },
    {
      title: '汇总信息',
      rule: {
        groupCheck: len => len >= 1,
        propCheck: len => len == 1,
        contrastCheck: contrastType => !contrastType || contrastType.toLowerCase() == 'current',
      },
      dependency: (styleSet, type) => {
        var code = 'allDimValues'
        return !Object.keys(styleSet).includes(code) || !styleSet[code]
      },
      list: [
        {
          code: 'aggregation',
          type: 'radio',
          label: '汇总指标',
          defaultValue: 'none',
          radios: [
            { value: 'sum', label: '总计' },
            { value: 'average', label: '平均值' },
            { value: 'median', label: '中位数' },
            { value: 'none', label: '不显示' },
          ],
        },
      ],
    },
  ],
  customrectangle: [
    {
      title: '矩形堆积图显示',
      list: [
        {
          code: 'showGroup',
          type: 'switch',
          label: '分组名称',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'showNumber',
          type: 'switch',
          label: '数值',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'enablePencentage',
          type: 'switch',
          label: '百分比',
          defaultValue: true,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
        },
        {
          code: 'enableCompress',
          type: 'switch',
          label: '其它',
          desc: '搭建的图表中若同一分组中的部分数值小于等于一定量时，统一归入"其它"分组中显示。',
          defaultValue: false,
          radios: [
            { value: true, label: '显示' },
            { value: false, label: '不显示' },
          ],
          rule: {
            groupCheck: len => len == 1,
            propCheck: len => len >= 1,
          },
        },
        {
          code: 'compressWay',
          type: 'select',
          label: '',
          defaultValue: 'percentage',
          select: [
            { value: 'number', label: '按数值' },
            { value: 'percentage', label: '按百分比' },
            { value: 'top', label: '按TOP排序' },
          ],
          dependency: (styleSet, type) => {
            var code = 'enableCompress'
            return styleSet[code] && checkDependency(styleSet, type, code)
          },
        },
        {
          code: 'criticalValue',
          type: 'input',
          label: '',
          defaultValue: '10',
          dependency: (styleSet, type) => {
            var code = 'enableCompress'
            return styleSet[code] && checkDependency(styleSet, type, code)
          },
        },
      ],
    },
  ],
}

export default chartStyleSetList
