<template>
  <div>
    <div class="appearstyle" v-for="(item, index) in showAppear.filter(x => x.enabled).filter(x => !x.dependency || x.dependency(styleSet, type))" :key="index">
      <h4>{{ item.title }}</h4>
      <ul>
        <li v-for="(set, sidx) in item.list.filter(y => y.enabled)" :key="sidx">
          <template v-if="!set.dependency || set.dependency(styleSet, type)">
            <label :class="{ line_bar_90: set.label.length == 6 }">
              {{ set.label }}
              <el-popover v-if="set.desc" popper-class="wenhao-pop" placement="top-start" title="" width="300" trigger="hover">
                {{ set.desc }}
                <i class="wenhao styleset_wenhao iconfont iconwenhao4" slot="reference"></i>
              </el-popover>
              {{ set.label ? ':' : '' }}
            </label>

            <div class="label-r" v-if="set.type == 'radio'">
              <el-radio-group v-model="styleSet[set.code]" @change="setChange(set)">
                <el-radio v-for="(r, ridx) in set.radios" :key="ridx" :label="r.value" :disabled="r.disabled">
                  {{ r.label }}
                  <el-popover v-if="r.desc" popper-class="wenhao-pop" placement="top-start" title="" trigger="hover">
                    {{ r.desc }}
                    <i class="wenhao styleset_wenhao iconfont iconwenhao4" slot="reference"></i>
                  </el-popover>
                </el-radio>
              </el-radio-group>
            </div>
            <div class="label-r" v-if="set.type == 'switch'">
              <el-switch v-model="styleSet[set.code]" @change="setChange(set)"> </el-switch>
            </div>
            <div class="label-r" v-if="set.type == 'select'">
              <el-select v-model="styleSet[set.code]" @change="setChange(set)" placeholder="请选择" size="mini">
                <el-option v-for="ope in set.select" :key="ope.value" :label="ope.label" :value="ope.value"></el-option>
              </el-select>
            </div>
            <div class="label-r" v-if="set.type == 'selectMultiple'">
              <el-select class="selectMultiple" v-model="styleSet[set.code]" multiple @change="setChange(set)" placeholder="请选择" size="mini">
                <el-option v-for="ope in set.selectMultiple" :key="ope.value" :label="ope.label" :value="ope.value"></el-option>
              </el-select>
            </div>
            <div class="label-r label-r-input" v-if="set.type == 'input'">
              <span v-if="set.code === 'criticalValue'">≤ </span>
              <span v-if="set.code === 'criticalValue' && styleSet['compressWay'] === 'top'">TOP</span>
              <el-input
                v-model="styleSet[set.code]"
                size="mini"
                @input="onCriticalValueChange"
                :placeholder="set.placeholder ? set.placeholder : ''"
              ></el-input>

              <!-- 其它 按百分比 -->
              <span v-if="set.code === 'criticalValue' && styleSet['compressWay'] === 'percentage'">%</span>
            </div>
            <div class="label-r" v-if="set.type == 'numberinput'">
              <el-input
                v-model.number="styleSet[set.code]"
                type="number"
                size="mini"
                :maxlength="set.maxLen ? set.maxLen : ''"
                @input="onInput"
                :placeholder="set.placeholder ? set.placeholder : ''"
              ></el-input>
            </div>
            <div class="label-r dis_flex" v-if="set.type == 'areaYAxis' && styleSet[set.code]">
              <el-input placeholder="自动" v-model.number="styleSet[set.code][0]" type="number" size="mini" @input="onInput(set)"></el-input>
              <span class="margin_lr_5">-</span>
              <el-input placeholder="自动" v-model.number="styleSet[set.code][1]" type="number" size="mini" @input="onInput(set)"></el-input>
            </div>
            <div class="label-r" v-if="set.type == 'slider' && styleSet[set.code]">
              <el-slider
                v-model="styleSet[set.code]"
                input-size="small"
                :min="set.defaultMin"
                :max="set.defaultMax"
                :show-tooltip="true"
                :format-tooltip="_ => formatTooltip(styleSet[set.code])"
                @change="setChange(set)"
              ></el-slider>
            </div>

            <!-- Y轴数字格式 -->
            <div class="label-r" v-if="set.type == 'yAxisformat' && styleSet[set.code]">
              <ul class="YAxis-format dis_flex">
                <el-tooltip class="item" effect="dark" content="千分位" placement="top-start">
                  <li @click="propclick(set, 'thousands')" :class="{ active: styleSet[set.code].thousands }">
                    <span>,</span>
                  </li>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="增加小数位数" placement="top-start">
                  <li @click="decpointlick(set, 'add')">
                    <a href="javascript:;"
                      ><span><i class="iconfont  iconjiantouarrow506"></i>.0 .00</span></a
                    >
                  </li>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="减少小数位数" placement="top-start">
                  <li @click="decpointlick(set, 'sub')">
                    <a href="javascript:;"
                      ><span>.00 <i class="iconfont iconjiantouarrow484"></i>.0</span></a
                    >
                  </li>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="百分比" placement="top-start">
                  <li @click="propclick(set, 'percent')" :class="{ active: styleSet[set.code].percent }">
                    <span>%</span>
                  </li>
                </el-tooltip>
              </ul>
            </div>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  props: {
    showAppear: {
      type: Array,
    },
    styleSet: {
      type: Object,
    },
    type: {
      type: String,
    },
    chartidx: {
      type: Number,
    },
    columns: {
      type: Array,
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    setChange(set) {
      if (set.code == 'allDimValues') {
        this.$emit('setChange', set)
        // setTimeout(() => {
        //   this.$eventBus.$emit('allValuesSearch', this.chartidx)
        // }, 400)
        return
      } else if (['enablePencentage'].includes(set.code)) {
        this.styleSet.areaYAxis = ['', '']
      }

      //双轴图
      if (this.type === 'biaxial_graph') {
        const lineprops = this.styleSet['lineprops']
        const nextLineprops = this.styleSet['nextLineprops']
        const mlist = this.columns.filter(x => x.usage == 'Measure').map(x => x.alias)
        if (set.code === 'lineprops') {
          this.styleSet['nextLineprops'] = mlist.filter(x => !lineprops.includes(x))
        }
        if (set.code === 'nextLineprops') {
          this.styleSet['lineprops'] = mlist.filter(x => !nextLineprops.includes(x))
        }
      }
      if ('compressWay' == set.code) {
        this.styleSet['criticalValue'] = this.styleSet.compressWay == 'percentage' ? '10' : ''
      }
      this.$emit('setChange', set)
    },
    onCriticalValueChange(set) {
      if (this.styleSet['criticalValue']) {
        let value = this.styleSet['criticalValue']
        value = this.correctInputNumber(value)
        if (['top'].includes(this.styleSet['compressWay'])) {
          value = value.replace(/\./g, '')
        }
        this.styleSet['criticalValue'] = value
      }
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        if (this.styleSet['criticalValue']) {
          let value = this.styleSet['criticalValue']
          this.styleSet['criticalValue'] = value.replace(/\.$/, '')
        }
        this.$emit('setChange', set)
      }, 500)
    },
    correctInputNumber(value) {
      let pattern = /^\.+/
      let pattern1 = /^(\d+\.)(\.+)/
      let pattern2 = /^(0+)(\d+)/
      let pattern3 = /^(\d+\.\d+)(\.+)/
      let pattern4 = /([^\d\.])/g
      value = value.replace(pattern, '') //...23 -->23
      value = value.replace(pattern1, '$1') //2.. -->2.
      value = value.replace(pattern2, '$2') //02 -->2
      value = value.replace(pattern3, '$1') //2.2.-->2.2
      value = value.replace(pattern4, '') //abac3236.23dc -->3236.23
      while (pattern.test(value) || pattern1.test(value) || pattern2.test(value) || pattern3.test(value) || pattern4.test(value)) {
        value = this.correctInputNumber(value)
      }
      return value
    },
    propclick(set, item) {
      this.$set(this.styleSet[set.code], item, !this.styleSet[set.code][item])
      this.$emit('setChange', set)
    },
    decpointlick(set, type) {
      // format.digit 位数null 时根据值的最大小数位数来加减 位数有值时根据当前位数加减
      let format = this.styleSet[set.code]
      if (!format.digit && format.digit !== 0) {
        format.digit = 0
      }
      if (type === 'add') {
        format.digit++
      } else {
        format.digit = format.digit > 0 ? format.digit - 1 : 0
      }
      this.$emit('setChange', set)
    },

    onInput: _.debounce(function(set, code) {
      this.$emit('setChange', set)
    }, 500),
    formatTooltip(number) {
      return number + '%'
    },
  },
}
</script>

<style lang="scss" scoped>
.appearstyle {
  ul {
    li {
      > label.line_bar_90 {
        width: 90px !important;
      }

      label {
        .wenhao {
          span {
            top: -80px;
          }
        }
      }
      .label-r {
        .el-switch {
          ::v-deep .el-switch__core {
            width: 50px !important;
          }
          &.is-checked {
            ::v-deep .el-switch__core {
              @include high_border1($highcolor-cheng);
              @include high_bg1($highcolor-cheng);
            }
          }
        }
        .YAxis-format {
          li {
            flex-shrink: 0;
            width: 28px;
            height: 28px;
            box-sizing: border-box;
            border: 1px solid #dcdfe6;
            border-left: 0;
            cursor: pointer;
            color: #909399;
            display: flex;
            align-items: center;
            justify-content: center;
            &:first-of-type {
              border-left: 1px solid #dcdfe6;
              span {
                font-weight: bold;
                font-size: 18px;
                height: 20px;
              }
            }
            &.active {
              @include high_border1($highcolor-cheng);
              @include high_color1($highcolor-cheng);
            }
            a {
              flex-shrink: 0;
              display: block;
              width: calc(100% + 1px);
              height: 26px;
              color: #909399;
              border-top: 1px solid #dcdfe6;
              border-bottom: 1px solid #dcdfe6;
              display: flex;
              align-items: center;
              justify-content: center;
              &:active {
                @include high_border1($highcolor-cheng);
                @include high_color1($highcolor-cheng);
              }
              i {
                font-size: 10px;
              }
            }
            span {
              display: block;
              width: 100%;
              text-align: center;
              font-size: 12px;
              line-height: 10px;
              word-wrap: break-word;
              transform: scale(0.95);
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.selectMultiple {
  line-height: 28px;
  .el-select__tags {
    max-width: 144px !important;
    > span {
      display: block;
      width: 100%;
    }
    .el-tag {
      width: 100%;
      line-height: 20px;
      .el-select__tags-text {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-tag__close.el-icon-close {
        right: 7px;
        top: -6px;
      }
    }
  }
  .el-input {
    .el-input__suffix {
      right: 0;
    }
  }
}
</style>
