<template>
  <div class="preview">
    <div class="preview-hd">
      <h3>{{ dashboard.nameCN }}</h3>
    </div>
    <div class="mbv2list" v-if="gridData && gridData.length > 0">
      <myScroll>
        <grid-layout
          :layout.sync="gridData"
          :responsive="true"
          :cols="{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }"
          :breakpoints="{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }"
          :col-num="12"
          :row-height="20"
          :is-draggable="system == 'bi'"
          :is-resizable="system == 'bi'"
          :vertical-compact="true"
          :margin="[10, 10]"
          :use-css-transforms="true"
          @layout-updated="layoutUpdatedEvent"
        >
          <grid-item
            v-for="item in gridData"
            :key="item.id"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :minW="2"
            :minH="2"
            @resize="resizeEvent"
            @resized="resizedEvent"
            @moved="movedEvent"
            v-loading="chartData[item.i] && chartData[item.i].loading"
            :element-loading-spinner="'cardloading loading' + basicInfo.project.mainColor"
            element-loading-background="rgba(0, 0, 0, 0)"
            drag-allow-from=".gridtitle"
            drag-ignore-from=".charts-box"
          >
            <div class="grid-item-box">
              <cardSet
                v-if="chartData[item.i]"
                :dashboard="dashboard"
                :gridData="gridData"
                :chartidx="item.i"
                :chart="chartData[item.i]"
                @chartchange="ct => chartchange(ct, item.i)"
                @delmobile="delmobile(item.i)"
                @refresh="refresh(item.i)"
              />
              <gridBar
                v-if="chartData[item.i]"
                :chart="chartData[item.i]"
                @timechange="timeitem => timechange(timeitem.timeidx, timeitem.time, chartData[item.i], item.i)"
                @searchchange="searchitem => searchchange(searchitem, item.i)"
              />
              <div
                class="charts-box"
                v-if="chartData[item.i]"
                :style="{
                  height:
                    chartData[item.i] && chartData[item.i].timeRange
                      ? chartData[item.i].reqObj.contrastType == 'contrast' || chartData[item.i].searchCode == 'contrast'
                        ? 'calc(100% - 65px)'
                        : 'calc(100% - 40px)'
                      : 'calc(100% - 15px)',
                }"
              >
                <template v-if="!chartData[item.i].canVisit">
                  <div class="nodata dis_flex_center">
                    <div class="text_center">
                      <img src="~@/assets/images/nodata/canVisit.png" alt />
                      <span>暂无卡片数据权限</span>
                    </div>
                  </div>
                </template>
                <template v-else-if="chartData[item.i].option && chartData[item.i].option.noData">
                  <div class="nodata dis_flex_center">
                    <div>
                      <img src="~@/assets/images/nodata-bg.png" alt />
                      <span>暂无数据</span>
                    </div>
                  </div>
                </template>
                <vue-bi-echarts-mobile
                  v-else-if="chartData[item.i] && chartData[item.i].option && chartData[item.i].cardinfos"
                  :ref="'chart' + chartData[item.i].i"
                  :key="'bicardkey' + chartData[item.i].id"
                  :cardinfos="chartData[item.i].cardinfos"
                  :cardposition="chartData[item.i]"
                  :ismobile="true"
                  :rowheight="chartData[item.i].timeRange ? item.h * 20 + (item.h - 1) * 15 - 101 : item.h * 20 + (item.h - 1) * 15 - 68"
                  :style="{
                    height: !['table', 'table_hotmap', 'table_path'].includes(chartData[item.i].reqObj.mode)
                      ? '100%'
                      : chartData[item.i].option && chartData[item.i].option.opts && chartData[item.i].option.opts.enablePivot == 'full'
                      ? 'calc(100% - 100px)'
                      : (chartData[item.i].timeRange ? item.h * 20 + (item.h - 1) * 15 - 101 : item.h * 20 + (item.h - 1) * 15 - 68) + 'px',
                  }"
                ></vue-bi-echarts-mobile>
              </div>
            </div>
          </grid-item>
        </grid-layout>
      </myScroll>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import VueGridLayout from '@/script/npmjs/vue-grid-layout.common.js'
import cardSet from './components/cardSet.vue'
import gridBar from './components/gridBar.vue'
import previewJS from './js/preview'
import mobilejs from './js/mobile'
import { updateMobilePosition } from '@/api/mobileapi.js'

export default {
  name: 'preview',
  props: ['dashboard', 'bookmarks', 'originResults', 'originChartData'],
  data() {
    return {
      gridData: [],
      chartData: [],
    }
  },
  computed: {
    ...mapState({
      system: state => state.system,
      basicInfo: state => state.basicInfo,
    }),
  },
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    cardSet,
    gridBar,
  },
  watch: {
    'originResults.length': function() {
      this.reschange()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // 是否默认排版 mobileSettings/bookmarks 为null 面板为默认排版， bookmarks 为空数组[] 面板为空
      this.gridData = previewJS.initgrid(this.dashboard, this.originChartData)
      // this.chartData = previewJS.initchart(this.dashboard,this.originChartData)
    },
    reschange() {
      const mobilebookmarks = this.dashboard?.mobileSettings?.bookmarks ?? this.bookmarks
      if (mobilebookmarks && mobilebookmarks.length > 0) {
        this.chartData = mobilebookmarks.map(x => '')
        mobilebookmarks.forEach((x, i) => {
          const findmark = this.chartData.find(c => c && c.id === x.id)
          if (!findmark) {
            const findres = this.originResults.find(res => res.id === x.id)
            if (!findres) return
            const findoriginchart = this.originChartData.find(c => c.id === x.id)
            let copychart = _.cloneDeep(findoriginchart)
            // if (x.settings) {
            //   copychart.cardinfos.settings = JSON.parse(x.settings)
            // }
            // this.chartData[i] = findoriginchart
            this.$set(this.chartData, i, copychart)
          }
        })
      }
    },
    addcard(item) {
      const findid = this.chartData.find(x => x.id === item.id)
      if (findid) {
        this.$message.error('不能重复添加')
        return
      }
      this.chartData.push(item)
      const len = this.gridData.length
      const newgrid = { id: item.id, x: 0, y: len * 16, w: 12, h: 6, i: len }
      this.gridData.push(newgrid)
      const bookmarks = this.gridData.map(x => {
        return {
          id: x.id,
          position: {
            row: x.y,
            col: x.x,
            x: x.w,
            y: x.h,
          },
        }
      })
      this.updatePosition(bookmarks)
    },
    layoutUpdatedEvent(newLayout) {
      let bookmarks = newLayout.map((x, idx) => {
        return {
          id: x.id,
          position: {
            row: x.y,
            col: x.x,
            x: x.w,
            y: x.h,
          },
        }
      })
      this.updatePosition(bookmarks)
    },
    updatePosition(bookmarks) {
      const param = {
        dashboardID: this.$route.hash.replace('#/', ''),
        bookmarks,
      }
      try {
        updateMobilePosition(param).then(v => {})
      } catch (err) {}
    },
    resizeEvent(i, issign, state) {},
    resizedEvent(i) {
      if (this.chartData[i] && this.chartData[i].cardinfos) {
        const cardresizekey = this.chartData[i]?.cardinfos?.cardresizekey ?? 'c1234'
        this.$set(this.chartData[i].cardinfos, 'cardresizekey', cardresizekey + i)
      }
    },
    movedEvent(i) {},
    chartchange(item, idx) {
      let { copyChart, isAllDimValues } = item
      if (isAllDimValues) {
        this.$set(this.chartData, idx, copyChart)
        mobilejs.searchchange(this, idx, this.chartData[idx].reqObj)
      } else {
        copyChart.cardinfos.cardkey = copyChart.cardinfos.cardkey + '1'
        this.$set(this.chartData, idx, copyChart)
      }
    },
    refresh(idx) {
      const el = this.chartData[idx]
      mobilejs.getSearch(this, el, idx, 'refresh')
    },
    delmobile(idx) {
      this.gridData.splice(idx, 1)
      this.gridData = this.gridData.map((x, i) => {
        x.i = i
        return x
      })
      this.chartData.splice(idx, 1)
      const bookmarks = this.gridData.map(x => {
        return {
          id: x.id,
          position: {
            row: x.y,
            col: x.x,
            x: x.w,
            y: x.h,
          },
        }
      })
      this.updatePosition(bookmarks)
      this.$parent.$refs['rightcardlistref'].updatelist(this.chartData)
    },
    timechange(timeidx, time, el, idx) {
      if (timeidx === 1) {
        this.gettimeRange1(time, el, idx)
      } else if (timeidx === 2) {
        this.gettimeRange2(time, el, idx)
      }
    },
    //时间修改1
    gettimeRange1(time, el, idx, scheduler) {
      let newitem = {}
      newitem.v2time = [time]
      newitem.index = idx
      newitem.item = el
      if (el.searchCode === 'combination') {
        cardinitMark.gettimeRange(this, newitem)
      } else {
        mobilejs.gettimeRange(this, newitem, '')
      }
    },
    //时间修改2
    gettimeRange2(time, el, idx) {
      let newitem = {}
      newitem.v2time = [time]
      newitem.index = idx
      newitem.item = el
      if (el.searchCode === 'combination') {
        cardinitMark.gettimeRange(this, newitem)
      } else {
        mobilejs.gettimeRange(this, newitem, 'contrast')
      }
    },
    //刷新 对比 按天
    searchchange(searchitem, idx) {
      this.chartData[idx].option = null
      mobilejs.searchchange(this, idx, this.chartData[idx].reqObj, searchitem.type, searchitem.contrast)
    },
  },
}
</script>

<style lang="scss">
@import './css/preview.scss';
</style>
