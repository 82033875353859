const previewJS = {
  initgrid(dashboard, originChartData) {
    const mobilebookmarks = dashboard?.mobileSettings?.bookmarks ?? null
    if (mobilebookmarks) {
      if (mobilebookmarks.length > 0) {
        const grids = mobilebookmarks.map((x, i) => {
          return {
            id: x.id,
            x: x.position.col,
            y: x.position.row,
            w: x.position.x,
            h: x.position.y,
            i,
          }
        })
        return grids
      } else {
        return []
      }
    } else {
      const grids = originChartData.map((x, i) => {
        return {
          id: x.id,
          x: 0,
          y: i * 6,
          w: 12,
          h: 6,
          i,
        }
      })
      return grids
    }
  },
  initchart(dashboard, originChartData) {
    const mobilebookmarks = dashboard?.mobileSettings?.bookmarks ?? null
    if (mobilebookmarks) {
      if (mobilebookmarks.length > 0) {
        let charts = []
        mobilebookmarks.forEach(x => {})
        const grids = mobilebookmarks.map((x, i) => {
          return {
            id: x.id,
            x: x.position.col,
            y: x.position.row,
            w: x.position.x,
            h: x.position.y,
            i,
          }
        })
        return grids
      }
    }
  },
}

export default previewJS
