<template>
  <div class="mobilegridBar">
    <!-- <div class="gridtitle wordellipsis">
      {{ chart.reqObj.title }}
    </div> -->
    <div class="gridoption clearfix">
      <div
        v-if="chartd.timeRange"
        class="set-time"
        :style="{
          'max-width': chartd.timeRange[1] && (chartd.reqObj.contrastType == 'contrast' || chartd.searchCode == 'contrast') ? '530px' : '370px',
        }"
      >
        <div class="timebox dis_flex" v-if="chartd.timeRange">
          <extDatetime
            :key="JSON.stringify(chartd.timeRange[0]) + '01' + chartd.index"
            :proptimeidx="0"
            :ismobile="true"
            :propitemidx="chartd.index"
            :maxInterval="getTimePickerInterval(chartd, 0)"
            :propDate="chartd.timeRange"
            @timechange="time => gettimeRange(1, time)"
            class="lefttime"
          />
          <el-select
            class="set-contrast"
            :class="{ twowords: !['nocontrast', 'lastandcircle'].includes(chartd.reqObj.contrastType) }"
            popper-class="thmemBottomMenu"
            v-if="chartd.reqObj.contrastType && !chartd.reqObj.top"
            v-model="chartd.reqObj.contrastType"
            size="mini"
            @change="searchchange(chartd.reqObj.contrastType, 'contrast')"
          >
            <el-option v-for="c in contrastoptions" :key="c.value" :label="c.label" :value="c.value"></el-option>
          </el-select>
          <span class="contrastspan" v-if="chartd.searchCode == 'contrast'">对比</span>
          <extDatetime
            v-if="chartd.timeRange[1] && (chartd.reqObj.contrastType == 'contrast' || chartd.searchCode == 'contrast')"
            :key="JSON.stringify(chartd.timeRange[1]) + '11' + chartd.index"
            :proptimeidx="1"
            :ismobile="true"
            :propitemidx="chartd.index"
            :maxInterval="getTimePickerInterval(chartd, 1)"
            :propDate="chartd.timeRange"
            @timechange="time => gettimeRange(2, time)"
            class="righttime"
          />
          <div
            class="set-slot"
            :class="{ twowords: !['hour'].includes(chartd.otherset.slot) }"
            v-if="chartd.otherset.slot && slotshowarr.includes(chartd.otherset.slot) && !chartd.reqObj.top"
          >
            <el-select v-model="chartd.otherset.slot" popper-class="thmemBottomMenu" size="mini" @change="searchchange(chartd.otherset.slot, 'slot')">
              <el-option v-for="s in slotoptions" :key="s.value" :label="s.label" :value="s.value"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="!chartd.timeRange" class="nodate"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'gridBar',
  props: ['chart'],
  data() {
    return {
      chartd: '',
      contrastoptions: [
        { value: 'nocontrast', label: '无对比' },
        { value: 'contrast', label: '对比' },
        { value: 'last', label: '同比' },
        { value: 'circle', label: '环比' },
        { value: 'lastandcircle', label: '同环比' },
      ],
      slotoptions: [
        { value: 'hour', label: '按小时' },
        { value: 'day', label: '按天' },
        { value: 'week', label: '按周' },
        { value: 'month', label: '按月' },
        { value: 'quarter', label: '按季度' },
      ],
      slotshowarr: ['hour', 'day', 'week', 'month', 'quarter'],
    }
  },
  components: {},
  computed: {
    ...mapState({
      system: state => state.system,
      basicInfo: state => state.basicInfo,
      festivals: state => state.festivals,
      weathers: state => state.weathers,
      projectMalls: state => state.projectMalls,
    }),
  },

  created() {
    this.chartd = this.chart
  },
  methods: {
    getTimePickerInterval(chart, index) {
      return -1
    },
    gettimeRange(timeidx, time) {
      this.$emit('timechange', { timeidx: timeidx, time: time })
    },
    searchchange(contrast, type) {
      this.$emit('searchchange', { contrast: contrast, type: type })
    },
  },
}
</script>

<style lang="scss">
.mobilegridBar {
  max-width: 100%;
  overflow: hidden;
  .timebox {
    // flex-wrap: wrap;
    .set-contrast {
      width: 60px;
      margin-left: 5px;
      flex-shrink: 0;
      border: none !important;
    }
    .set-slot {
      width: 60px;
      margin-left: auto;
      border: none !important;
    }
    .twowords {
      width: 50px;
    }
    .contrastspan {
      font-size: 12px;
      margin: 0 10px 0 18px;
      color: #adb5bd;
    }
    .el-input {
      &.is-disabled {
        .el-input__inner {
          cursor: auto;
        }
      }
      .el-input__inner {
        height: 20px;
        line-height: 20px;
        padding-left: 0;
        padding-right: 20px;
        color: #adb5bd;
        background: none;
        border: none !important;
      }
      .el-input__suffix {
        width: 20px;
        height: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        .el-input__icon {
          width: 20px;
          height: 20px;
          line-height: 20px;
        }
      }
      .el-select__caret {
        font-size: 12px;
      }
    }
    .righttime {
    }
  }
}
</style>
