<template>
  <div class="proptitle">
    <div v-if="propsets.groups && propsets.groups.length > 0" class="target">
      <h4>分组名称</h4>
      <div class="group">
        <template v-for="(item, idx) in propsets.groups">
          <el-input :key="idx" size="small" v-model="item.title" @input="setChange" @blur="blurinput('groups', idx)"></el-input>
        </template>
      </div>
    </div>
    <div v-if="propsets.props && propsets.props.length > 0" class="target">
      <h4>指标名称</h4>
      <div class="targetbox">
        <div class="prop dis_flex" v-for="(item, idx) in propsets.props" :key="idx">
          <el-input size="small" v-model="item.title" @input="setChange" @blur="blurinput('props', idx)"></el-input>
          <ul class="prop-r dis_flex">
            <el-tooltip class="item" effect="dark" content="千分位" placement="top-start">
              <li @click="propclick(idx, 'thousands')" :class="{ active: item.format.thousands }">
                <span>,</span>
              </li>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="增加小数位数" placement="top-start">
              <li @click="decpointlick(idx, 'add')">
                <a href="javascript:;"
                  ><span><i class="iconfont  iconjiantouarrow506"></i>.0 .00</span></a
                >
              </li>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="减少小数位数" placement="top-start">
              <li @click="decpointlick(idx, 'sub')">
                <a href="javascript:;"
                  ><span>.00 <i class="iconfont iconjiantouarrow484"></i>.0</span></a
                >
              </li>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="百分比" placement="top-start">
              <li @click="propclick(idx, 'percent')" :class="{ active: item.format.percent }">
                <span>%</span>
              </li>
            </el-tooltip>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['cardinfos', 'setting'],
  data() {
    return {
      propsets: {},
      searchres: '',
      columns: [],
      copyColumns: [],
    }
  },
  created() {
    this.searchres = this.cardinfos.result
    this.columns = this.searchres.columns
    this.copyColumns = JSON.parse(JSON.stringify(this.columns))
    this.init()
  },
  methods: {
    init() {
      const propsets = this.setting.propSet
      // 兼容早期卡片 没有 propSet ，从原始 columns 和 filterset 获取 分组和指标
      const groupColumns = this.copyColumns.filter(x => x.usage === 'Group')
      const respropColumns = this.copyColumns.filter(x => x.usage === 'Measure')
      const filterprops = this.setting.filterSet.columns.filter(x => !x.usage || x.usage === 'Measure')
      if (!propsets) {
        const groups = groupColumns.map(x => {
          return {
            alias: x.alias,
            fullAlias: x.fullAlias,
            title: x.title,
          }
        })
        const props = filterprops.map(x => {
          const targ = respropColumns.find(t => t.fullAlias == x.fullAlias)
          const format = {
            thousands: true, // 千分位
            percent: targ && targ.displayType === 'PERCENTAGE', // 百分比
            digit: null, // 小数点位数,null 不做处理，0，1，2，3 是保留几位小数
          }
          return {
            alias: x.alias,
            fullAlias: x.fullAlias,
            title: x.name,
            format,
          }
        })

        this.propsets = {
          groups,
          props,
        }
      } else {
        // 如果有 propsets ，对比原始 groups 得到最新的 propsets
        const groups = groupColumns.map(x => {
          const targ = propsets && propsets.groups && propsets.groups.find(t => t.fullAlias == x.fullAlias)
          if (!targ) {
            return {
              alias: x.alias,
              fullAlias: x.fullAlias,
              title: x.title,
            }
          } else {
            return {
              alias: x.alias,
              fullAlias: x.fullAlias,
              title: targ.title,
            }
          }
        })
        // 如果有 propsets ，对比原始 columns 得到最新的 propsets  排除： 留存/分布
        const usage = this.cardinfos?.parameter?.usage ?? ''
        let props = []
        if (!['Scatter', 'Retention'].includes(usage)) {
          props = respropColumns.map(x => {
            const targ = propsets && propsets.props && propsets.props.find(t => t.fullAlias == x.fullAlias)
            if (!targ) {
              return {
                alias: x.alias,
                fullAlias: x.fullAlias,
                title: x.title,
                format: {
                  thousands: true,
                  percent: x.displayType === 'PERCENTAGE', // 百分比
                  digit: x.displayType === 'PERCENTAGE' ? 2 : null,
                },
              }
            } else {
              return {
                alias: x.alias,
                fullAlias: x.fullAlias,
                title: targ.title,
                format: targ.format,
              }
            }
          })
        }

        this.propsets = {
          groups,
          props,
        }
      }
      // this.setChange()
    },
    propclick(idx, type) {
      this.$set(this.propsets.props[idx].format, type, !this.propsets.props[idx].format[type])
      this.setChange()
    },
    decpointlick(idx, type) {
      // format.digit 位数null 时根据值的最大小数位数来加减 位数有值时根据当前位数加减
      let format = this.propsets.props[idx].format
      if (!format.digit && format.digit !== 0) {
        const max_pointlen = this.getres_pointlen(this.propsets.props[idx].alias)
        format.digit = max_pointlen
      }
      if (type === 'add') {
        format.digit++
      } else {
        format.digit = format.digit > 0 ? format.digit - 1 : 0
      }
      this.setChange()
    },
    getres_pointlen(alias) {
      const res = this.searchres?.rows ?? []
      let maxlen = 0
      res.forEach(item => {
        const value = item[alias]
        if (value && String(value).includes('.')) {
          const point = String(value).split('.')[1]
          maxlen = maxlen > point.length ? maxlen : point.length
        }
      })
      return maxlen > 2 ? 2 : maxlen
    },
    setChange() {
      const setting = this.emitSetting()
      this.$emit('change-prop', setting)
    },
    emitSetting() {
      let copySetting = JSON.parse(JSON.stringify(this.setting))
      copySetting['propSet'] = this.propsets
      return copySetting
    },
    blurinput(type, idx) {
      let prop = this.propsets[type][idx]
      if (!prop.title && prop.title !== 0) {
        const column = this.copyColumns.find(x => x.fullAlias === prop.fullAlias)
        prop.title = column.title
        this.setChange()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.proptitle {
  .target {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dcdfe6;
    &:last-of-type {
      border-bottom: none;
    }
    h4 {
      margin-bottom: 0;
      line-height: 30px;
      font-size: 14px;
    }
    .group {
      .el-input {
        margin-bottom: 10px;
      }
    }
    .prop {
      margin-bottom: 10px;
      .el-input {
        ::v-deep .el-input__inner {
          border-right: 0;
          border-radius: 4px 0 0 4px;
        }
      }
      ul {
        li {
          flex-shrink: 0;
          width: 26px;
          height: 32px;
          box-sizing: border-box;
          border: 1px solid #dcdfe6;
          border-left: 0;
          cursor: pointer;
          color: #909399;
          display: flex;
          align-items: center;
          justify-content: center;
          &:first-of-type {
            border-left: 1px solid #dcdfe6;
            span {
              font-weight: bold;
              font-size: 18px;
              height: 20px;
            }
          }
          &.active {
            @include high_border1($highcolor-cheng);
            @include high_color1($highcolor-cheng);
          }
          a {
            flex-shrink: 0;
            display: block;
            width: calc(100% + 1px);
            height: 32px;
            color: #909399;
            border-top: 1px solid #dcdfe6;
            border-bottom: 1px solid #dcdfe6;
            display: flex;
            align-items: center;
            justify-content: center;
            &:active {
              @include high_border1($highcolor-cheng);
              @include high_color1($highcolor-cheng);
            }
            i {
              font-size: 10px;
            }
          }
          span {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 12px;
            line-height: 10px;
            word-wrap: break-word;
            transform: scale(0.95);
          }
        }
      }
    }
  }
}
</style>
