<template>
  <div class="appearfilter">
    <div class="target">
      <h4>维度</h4>
      <div class="targetbox">
        <div class="switchs">
          <label>隐藏未知：</label>
          <el-switch v-model="filters.hideUnknown" @change="setChange"> </el-switch>
        </div>
      </div>
    </div>
    <div class="target" v-show="filters.columns && filters.columns.length > 0">
      <h4>指标</h4>
      <div class="targetbox">
        <div class="switchs">
          <label>数值过滤：</label>
          <el-switch v-model="filters.filterValue" @change="setChange"> </el-switch>
        </div>
        <ul class="checkboxlist">
          <template v-for="(item, index) in filters.columns">
            <li v-if="item.usage === 'Measure'" :key="index">
              <el-checkbox v-model="item.selected" @change="setChange"> </el-checkbox>
              <div class="checkbox-r">
                <!-- <el-input size="small" v-model="item.name" @input="setChange(item, index)" @blur="blurinput(item, index)" :disabled="true"></el-input> -->
                <span>{{ item.name }}</span>
                <el-select
                  v-if="filters.filterValue && item.filterList.length > 1"
                  v-model="item.filterCode"
                  @change="changeFilterCode(item, index)"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option v-for="ope in item.filterList" :key="ope.value" :label="ope.label" :value="ope.value"></el-option>
                </el-select>
                <div v-if="filters.filterValue" class="filterval">
                  <BiInput class="filterinput" :point="4" v-model.number="item.start" @input="setChange" />
                  <span class="tipsi" v-if="item.filterCode.includes('Rate')">%</span>
                  <span class="tipsi">-</span>
                  <BiInput class="filterinput" :point="4" v-model.number="item.end" @input="setChange" />
                  <span class="tipsi" v-if="item.filterCode.includes('Rate')">%</span>
                </div>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['columns', 'setting', 'propchange', 'cardinfos'],
  data() {
    return {
      filters: {
        columns: null,
        codes: null,
      },
      copyColumns: [],
    }
  },
  watch: {
    propchange: function() {
      this.init()
    },
  },
  created() {
    this.copyColumns = JSON.parse(JSON.stringify(this.columns))
    this.init()
  },
  methods: {
    init() {
      const _this = this
      let path = this.$route.path
      this.filters = this.setting.filterSet
      let filterList = this.copyColumns.filter(x => x.usage === 'Measure' || x.usage === 'Rate')
      this.filters.columns.forEach(x => {
        let list = filterList.filter(f => f.alias == x.alias || (f.usage == 'Rate' && f.alias.includes(x.alias)))
        list = list.map(l => {
          return {
            value: l.alias,
            label: l.usage === 'Measure' ? '数值' : l.title,
          }
        })
        x.filterList = list
        if (x.usage !== 'Group') {
          if (x.usage == 'Measure' && !x['valList']) {
            const contrastType = this.cardinfos?.parameter?.contrastType ?? ''
            const rows = this.cardinfos?.result?.rows ?? ''
            const valarr = this.getValarr(contrastType, rows, x.alias)
            x['valList'] = valarr
          }
          // this.changeFilterCode(x)
        }
      })
    },
    setChange(item, idx) {
      if (item.name == '') return
      let filterSet = this.setting.filterSet
      filterSet.columns = this.filters.columns
      filterSet.codes = ['All']
      filterSet.hideUnknown = this.filters.hideUnknown
      filterSet.filterValue = this.filters.filterValue
      const setting = this.emitSetting()
      this.$emit('change-filterset', setting)
    },
    blurinput(item, idx) {
      if (item.name == '') {
        const que = this.$commonJs.getUrl(this.$route.hash)
        const columns = que.setting.filterSet.columns
        const blurcol = columns.find(x => {
          return x.fullAlias === item.fullAlias
        })
        item.name = blurcol.name

        const setting = this.emitSetting()
        this.$emit('change-filterset', setting)
      }
    },
    changeFilterCode(item, idx) {
      if (item.filterCode.includes('_contrastRate')) {
        const list = item.valList['contrastRate']
        item.start = list.start
        item.end = list.end
      } else if (item.filterCode.includes('_lastRate')) {
        const list = item.valList['lastRate']
        item.start = list.start
        item.end = list.end
      } else if (item.filterCode.includes('_circleRate')) {
        const list = item.valList['circleRate']
        item.start = list.start
        item.end = list.end
      } else {
        const list = item.valList['current']
        item.start = list.start
        item.end = list.end
      }
      const setting = this.emitSetting()
      this.$emit('change-filterset', setting)
    },
    emitSetting() {
      let copySetting = JSON.parse(JSON.stringify(this.setting))
      let filterSet = copySetting.filterSet
      filterSet.columns.forEach(x => {
        delete x.filterList
        delete x.valList
      })
      return copySetting
    },
    getValarr(contrastType, rows, alias) {
      let list = {}
      //指标值
      const nums = rows.map(r => {
        return r[alias]
      })
      const start = Math.min.apply(null, nums)
      const end = Math.max.apply(null, nums)
      list['current'] = { start: start, end: end }
      //对比
      if (contrastType && contrastType.includes('Contrast')) {
        let nums_c = rows.map(r => {
          let val = r[alias + '_contrastRate']
          if (String(val).includes('%')) {
            val = parseFloat(val.replace(/[,]/g, ''))
          }
          return val
        })
        nums_c = nums_c.filter(x => x != '-')
        const start_c = Math.min.apply(null, nums_c)
        const end_c = Math.max.apply(null, nums_c)
        list['contrastRate'] = { start: start_c, end: end_c }
      }
      //同比
      if (contrastType && ['Last', 'LastAndCircle'].includes(contrastType)) {
        let nums_l = rows.map(r => {
          let val = r[alias + '_lastRate']
          if (String(val).includes('%')) {
            val = parseFloat(val.replace(/[,]/g, ''))
          }
          return val
        })
        nums_l = nums_l.filter(x => x != '-')
        const start_l = Math.min.apply(null, nums_l)
        const end_l = Math.max.apply(null, nums_l)
        list['lastRate'] = { start: start_l, end: end_l }
      }
      //环比
      if (contrastType && ['Circle', 'LastAndCircle'].includes(contrastType)) {
        let nums_c = rows.map(r => {
          let val = r[alias + '_circleRate']
          if (String(val).includes('%')) {
            val = parseFloat(val.replace(/[,]/g, ''))
          }
          return val
        })
        nums_c = nums_c.filter(x => x != '-')
        const start_c = Math.min.apply(null, nums_c)
        const end_c = Math.max.apply(null, nums_c)
        list['circleRate'] = { start: start_c, end: end_c }
      }

      return list
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.appearfilter {
  .target {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dcdfe6;
    &:last-of-type {
      border-bottom: none;
    }
    h4 {
      margin-bottom: 0;
      line-height: 30px;
      font-size: 14px;
    }
    .targetbox {
      .switchs {
        margin-bottom: 10px;
        .el-switch {
          ::v-deep .el-switch__core {
            width: 50px !important;
          }
          &.is-checked {
            ::v-deep .el-switch__core {
              @include high_border1($highcolor-cheng);
              @include high_bg1($highcolor-cheng);
            }
          }
        }
      }
      .checkboxlist {
        li {
          display: flex;
          margin-bottom: 15px;
          .el-checkbox {
            margin-right: 10px;
            padding-top: 8px;
          }
          .checkbox-r {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            > span {
              line-height: 32px;
            }
            > .el-input {
              width: 100%;
              margin-bottom: 10px;
            }
            > .el-select {
              width: 100%;
              margin-bottom: 10px;
            }
            .filterval {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              .filterinput {
                flex: 1;
              }
              .tipsi {
                display: block;
                width: 20px;
                text-align: center;
                line-height: 32px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
