<template>
  <div class="mhead clearfix dis_flex">
    <div class="pageset">
      <el-dropdown trigger="click" @command="changedevice">
        <span v-if="deviceval" class="el-dropdown-link">
          {{ deviceval.label }} {{ deviceval.size.w }}x{{ deviceval.size.h }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu class="pagesetdropdown" slot="dropdown">
          <el-dropdown-item v-for="item in mobileDevices" :key="item.value" :command="item.value" :class="{ active: item.value === deviceval.value }">
            {{ item.label }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click" @command="changesize">
        <span v-if="sizeval" class="el-dropdown-link"> {{ sizeval.label }} <i class="el-icon-arrow-down el-icon--right"></i> </span>
        <el-dropdown-menu class="pagesetdropdown" slot="dropdown">
          <el-dropdown-item v-for="item in pageSizes" :key="item.value" :command="item.value" :class="{ active: item.value === sizeval.value }">{{
            item.label
          }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span @click="reset"><i class="iconfont iconzhongzhishaixuan"></i>重新布局</span>
      <span @click="empty"><i class="iconfont iconqingchu"></i> 清空画布</span>
    </div>
    <el-dropdown trigger="click" @command="changemobile">
      <span> <i class="iconfont iconshoujiduan"></i><span>移动端</span><i class="el-icon-arrow-down el-icon--right"></i> </span>
      <el-dropdown-menu class="layoutdropdown" slot="dropdown">
        <el-dropdown-item :class="{ active: layouttype === 'pc' }" command="pc"> <i class="iconfont iconpcduan"></i><span>PC端</span> </el-dropdown-item>
        <el-dropdown-item :class="{ active: layouttype === 'mobile' }" command="mobile">
          <i class="iconfont iconshoujiduan"></i>
          <span>移动端</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import jsondata from '../js/staticdata.js'
import { updateMobileReset } from '@/api/mobileapi.js'

export default {
  data() {
    return {
      layouttype: 'mobile',
      deviceval: '',
      mobileDevices: [],
      sizeval: '',
      pageSizes: [],
    }
  },
  components: {},
  created() {
    this.init()
  },
  methods: {
    init() {
      this.mobileDevices = jsondata.mobileDevices
      this.pageSizes = jsondata.pageSizes
      this.changedevice('iphone13')
      this.changesize(100)
    },
    changemobile(item) {
      console.log(item)
      this.layouttype = item
      if (item === 'pc') {
        this.$router.push({ path: '/v2/dashboard/Index/' + this.$route.params.projectID + this.$route.hash })
      }
    },
    changedevice(item, init) {
      this.deviceval = this.mobileDevices.find(x => x.value === item)
      if (init) {
        this.pagesetchange()
      }
    },
    changesize(item, init) {
      this.sizeval = this.pageSizes.find(x => x.value === item)
      if (init) {
        this.pagesetchange()
      }
    },
    pagesetchange() {
      this.$emit('pagesetchange', { devicewh: this.deviceval.size, size: this.sizeval.value })
    },
    reset() {
      this.$confirm('重新布局后将无法恢复, 确认重新布局吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const param = {
            dashboardID: Number(this.$route.hash.replace('#/', '')),
          }
          await updateMobileReset(param)
          this.$emit('reset')
          this.$message({
            type: 'success',
            message: '重新布局成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消重新布局',
          })
        })
    },
    empty() {
      this.$confirm('清空画布后将无法恢复, 确认清空画布吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          this.$emit('empty')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消清空画布',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.mhead {
  height: 60px;
  text-align: left;
  margin: 0 15px;
  border-radius: 6px;
  padding: 10px 15px;
  @include theme_bg1($theme-light);
  position: relative;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.12);
  .pageset {
    width: calc(100% - 380px);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin: 0 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        @include high_color1($highcolor-cheng);
      }
      i {
        font-size: 12px;
        margin-right: 5px;
      }
    }
  }
  > .el-dropdown {
    position: absolute;
    right: 15px;
    .iconshoujiduan {
      font-size: 12px;
      margin-right: 5px;
    }
    > span {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        @include high_color1($highcolor-cheng);
      }
    }
  }
  .el-dropdown {
    > span {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.layoutdropdown,
.pagesetdropdown {
  .el-dropdown-menu__item:hover,
  .active {
    @include high_color1($highcolor-cheng);
  }

  i {
    font-size: 12px;
    width: 16px;
    text-align: center;
    &.iconpcduan {
      font-size: 16px;
    }
  }
}
</style>
