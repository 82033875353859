<template>
  <div class="rightcardlist">
    <h3>未显示卡片</h3>
    <div class="cardlist">
      <myScroll>
        <div
          class="carditem"
          v-for="(item, index) in showChartData"
          :key="index"
          v-loading="item.loading"
          :element-loading-spinner="'cardloading loading' + basicInfo.project.mainColor"
          element-loading-background="rgba(0, 0, 0, 0)"
        >
          <div class="carditem-head ">
            <h3 class="wordellipsis">{{ item.reqObj.title }}</h3>
            <i class="el-icon-circle-plus" @click="addcard(index)"></i>
          </div>
          <!-- <gridBar v-if="item && item.cardinfos" :chart="item" :unchange="true" /> -->
          <div class="charts-box" v-if="item">
            <template v-if="!item.canVisit">
              <div class="nodata dis_flex_center">
                <div class="text_center">
                  <img src="~@/assets/images/nodata/canVisit.png" alt />
                  <span>暂无卡片数据权限</span>
                </div>
              </div>
            </template>
            <template v-else-if="item.option && item.option.noData">
              <div class="nodata dis_flex_center">
                <div>
                  <img src="~@/assets/images/nodata-bg.png" alt />
                  <span>暂无数据</span>
                </div>
              </div>
            </template>
            <vue-bi-echarts
              v-else-if="item.cardinfos"
              :ref="'chart' + item.i"
              :key="'bicardkey' + item.i"
              :cardinfos="item.cardinfos"
              :cardposition="item"
              :ismobile="true"
              :rowheight="200"
              :style="{
                height: !['table', 'table_hotmap', 'table_path'].includes(item.reqObj.mode)
                  ? '100%'
                  : item.option.opts && item.option.opts.enablePivot == 'full'
                  ? 'calc(100% - 100px)'
                  : 200 + 'px',
              }"
            ></vue-bi-echarts>
          </div>
        </div>
      </myScroll>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BI_config from '@/utils/config'
import Scheduler from '@/script/global/Scheduler.js'
import dashbdjs from '@/views/dashboard/dashboardjs'
import gridBar from './gridBar.vue'
export default {
  name: 'rightcardlist',
  props: ['dashboard', 'bookmarks', 'chartData'],
  data() {
    return {
      position: [],
      chartdatacache: [],
      showChartData: [],
    }
  },
  components: { gridBar },
  computed: {
    ...mapState({
      dashboardHash: state => state.dashboardHash,
      basicInfo: state => state.basicInfo,
      pageunit: state => state.pageunit,
      projectID: state => state.projectID,
      system: state => state.system,
    }),
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const mbbookmarks = this.dashboard?.mobileSettings?.bookmarks ?? null
      if (!mbbookmarks) {
        this.showChartData = []
      } else {
        const mbids = mbbookmarks.map(x => x.id)
        this.showChartData = this.chartData.filter(x => !mbids.includes(x.id))
      }
    },
    addcard(idx) {
      this.$emit('addcard', this.showChartData[idx])
      this.showChartData.splice(idx, 1)
    },
    updatelist(leftlist) {
      const mbids = leftlist.map(x => x.id)
      this.showChartData = this.chartData.filter(x => !mbids.includes(x.id))
    },
  },
  mounted() {},
}
</script>

<style lang="scss">
@import '../css/dashboard.scss';
</style>
