<template>
  <div class="appearSet">
    <el-tabs v-model="active" type="card">
      <el-tab-pane v-if="!['map3d'].includes(settings.type)" label="样式" name="style">
        <styleTemp
          :showAppear="showAppear"
          :styleSet.sync="settings.styleSet"
          :type="settings.type"
          :chartidx="chartidx"
          :columns="searchres.columns"
          @setChange="setStyleChange"
        ></styleTemp>
      </el-tab-pane>
      <el-tab-pane v-if="searchres.columns.length > 0 && settings.type !== 'table_more' && settings.type !== 'funnel'" label="名称" name="protitle">
        <prop-title :setting="settings" :cardinfos="chart.cardinfos" @change-prop="setpropChange"></prop-title>
      </el-tab-pane>
      <el-tab-pane v-if="searchres.columns.length >= 0 && !['table_more', 'funnel', 'map3d'].includes(settings.type)" label="过滤" name="filter">
        <filterset :columns="searchres.columns" :setting="settings" :propchange="propchange" :cardinfos="chart.cardinfos" @change-filterset="setfilterChange" />
      </el-tab-pane>
      <el-tab-pane v-if="!['table', 'table_more', 'table_hotmap', 'table_path', 'map3d'].includes(settings.type)" label="主题" name="theme">
        <div class="appeartheme">
          <div class="chartMainColor">
            <h4>主题皮肤</h4>
            <el-radio-group v-model="settings.themeSet['chartMainColor']" @change="changeTheme">
              <el-radio v-for="(r, ridx) in chartThemeSet.radios" :key="ridx" :label="r.label">{{ r.value }}</el-radio>
            </el-radio-group>
          </div>
          <div class="categoryColor">
            <h4>图表配色</h4>
            <div v-for="(t, tidx) in chartThemeSet.color" :key="tidx">
              <el-radio v-model="settings.themeSet['categoryColor']" :label="t.label" @change="setChange">{{ t.value }}</el-radio>
              <ul>
                <template v-for="(col, cidx) in t.list"> <li v-if="cidx < 10" :key="cidx" :style="{ background: col }"></li> </template>
              </ul>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import styleTemp from './styleTemp.vue'
import propTitle from './propTitle.vue'
import filterset from './filterset.vue'
import chartStyleSetList from './chartStyleData.js'
import jsondata from '../../js/staticdata.js'

export default {
  name: 'styleSet',
  props: ['chart', 'chartidx'],
  data() {
    return {
      active: 'style',
      allColor: jsondata.allColor,
      settings: '',
      showAppear: [],
      searchres: '',
      searchparams: '',
      analysisType: '',
      propchange: 0,
      chartThemeSet: null,
      debounce: _.debounce(
        isname => {
          this.debouncesetchange(isname)
        },
        300,
        {
          trailing: true,
        }
      ),
    }
  },
  components: { styleTemp, propTitle, filterset },
  computed: {
    ...mapState({
      basicInfo: state => state.basicInfo,
    }),
  },

  created() {
    this.init()
    this.initChartSet()
  },
  methods: {
    init() {
      this.settings = this.chart.cardinfos.settings
      this.searchres = this.chart.cardinfos.result
      this.analysisType = this.chart.cardinfos.cardopts.category
      this.searchparams = this.chart.cardinfos.parameter
    },
    initChartSet() {
      // 初始化获取当前类型的展示数据 和设置相关数据 集合成页面展示数据
      var { type } = this.settings
      // this.curChartSet = type + 'Temp'
      this.showAppear = _.cloneDeep(chartStyleSetList[type]) || []
      // 根据指标和分析维度 来设置选项是否禁用
      this.initSetTypes(this.searchres.columns, this.searchparams.paging, this.searchparams.contrastType, this.showAppear, this.analysisType, type)
      this.initStyleSet()
      this.initTheme('init')
    },
    initStyleSet() {
      const settings = JSON.parse(JSON.stringify(this.settings))
      var { styleSet, type } = settings
      let measureColumns = this.searchres.columns.filter(x => x.usage == 'Measure').map(x => ({ value: x.alias, label: x.title }))
      const groupColumns = this.searchres.columns.filter(x => x.usage == 'Group').map(x => ({ value: x.alias, label: x.title }))
      if (this.analysisType && this.analysisType.usage === 'Retention') {
        measureColumns = measureColumns.filter(x => x.value !== 'total')
      }
      if (styleSet.subline == 'measureField') {
        let sublineField = this.showAppear.flatMap(x => [...x.list]).find(x => x.code == 'sublineField')
        sublineField.select = measureColumns
        sublineField.defaultValue = measureColumns[0] && measureColumns[0].value
        if (!styleSet['sublineField']) styleSet['sublineField'] = sublineField.defaultValue
      }
      //柱图+线图
      if (type == 'bar_line') {
        let lineprops = this.showAppear.flatMap(x => [...x.list]).find(x => x.code == 'lineprops')
        lineprops.selectMultiple = measureColumns
        lineprops.defaultValue = measureColumns[1] && [measureColumns[1].value]
        if (!styleSet['lineprops']) styleSet['lineprops'] = lineprops.defaultValue
      }
      //双轴图
      if (type == 'biaxial_graph') {
        let lineprops = this.showAppear.flatMap(x => [...x.list]).find(x => x.code == 'lineprops')
        lineprops.selectMultiple = measureColumns
        if (styleSet['lineprops'].length == 0 && styleSet['nextLineprops'].length == 0) {
          lineprops.defaultValue = measureColumns[0] && [measureColumns[0].value]
          styleSet['lineprops'] = lineprops.defaultValue
        } else if (styleSet['lineprops'].length == 0 && styleSet['nextLineprops'].length !== measureColumns.length) {
          const Values = measureColumns.map(x => x.value).filter(x => !styleSet['nextLineprops'].includes(x))
          styleSet['lineprops'] = Values
        } else if (styleSet['nextLineprops'].length === measureColumns.length) {
          lineprops.defaultValue = []
        }
        //次Y轴
        let nextLineprops = this.showAppear.flatMap(x => [...x.list]).find(x => x.code == 'nextLineprops')
        nextLineprops.selectMultiple = measureColumns
        const nextValues = measureColumns.map(x => x.value).filter(x => !styleSet['lineprops'].includes(x))
        nextLineprops.defaultValue = nextValues
        styleSet['nextLineprops'] = nextValues
      }
      var codes = []
      var props = Object.keys(styleSet)
      this.showAppear.forEach(item => {
        if (item.enabled && (!item.dependency || item.dependency(styleSet, type))) {
          item.list.forEach(x => {
            if ((!x.dependency || x.dependency(styleSet, type)) && x.enabled) {
              if (!props.includes(x.code)) styleSet[x.code] = x.defaultValue

              if (x.select || x.radios) {
                if (!(x.select || x.radios).map(y => y.value).some(y => y == styleSet[x.code])) {
                  styleSet[x.code] = x.defaultValue
                }
              }
              if (x.selectMultiple) {
                if (!x.selectMultiple.map(y => y.value).some(y => styleSet[x.code].includes(y))) {
                  styleSet[x.code] = x.defaultValue
                }
              }
              //默认标题
              if (['line', 'bar', 'bar_line'].includes(this.settings.type)) {
                if (x.code == 'yAxisTitle' && !x.label) {
                  styleSet[x.code] = styleSet[x.code] ? styleSet[x.code] : measureColumns[0] && measureColumns[0].label
                }
                if (x.code == 'xAxisTitle' && !x.label) {
                  styleSet[x.code] = groupColumns.length > 0 && groupColumns[0].label ? (styleSet[x.code] ? styleSet[x.code] : groupColumns[0].label) : ''
                }
              }
              if (['biaxial_graph'].includes(this.settings.type)) {
              }
              if (['scatter', 'bubble'].includes(this.settings.type)) {
                if (x.code == 'yAxisTitle' && !x.label) {
                  styleSet[x.code] = styleSet[x.code] ? styleSet[x.code] : measureColumns[1] && measureColumns[1].label
                }
                if (x.code == 'xAxisTitle' && !x.label) {
                  styleSet[x.code] = styleSet[x.code] ? styleSet[x.code] : measureColumns[0] && measureColumns[0].label
                }
              }
              codes.push(x.code)
            } else {
              delete styleSet[x.code]
            }
          })
        } else {
          item.list.forEach(x => delete styleSet[x.code])
        }
      })
      //删除无效的字段
      props.forEach(x => {
        if (!codes.includes(x)) delete styleSet[x]
      })
      if (this.analysisType.category === 'template' && this.analysisType.usage === 'Path') {
        settings.styleSet.enableOccupation = true
      }

      this.showAppear = _.cloneDeep(this.showAppear)
      this.settings = _.cloneDeep(settings)
    },
    //加工表格设置
    initSetTypes(columns, paging, contrastType, showAppear, analysisType, chartType) {
      const _that = this
      let eventLen = columns.filter(x => x.usage == 'Measure').length
      let groupLen = columns.filter(x => x.usage == 'Group').length
      let firstGroup = columns.find(x => x.usage == 'Group')
      let lens = {
        eventLen: eventLen,
        groupLen: groupLen,
      }
      showAppear.forEach(el => {
        el.enabled = true
        if (el.rule) {
          el.enabled = _that.isSetTypeEnabled(paging, el.rule, lens, contrastType, firstGroup, analysisType, chartType)
        }
        if (el.enabled) {
          el.list.forEach(x => {
            x.enabled = !x.rule || _that.isSetTypeEnabled(paging, x.rule, lens, contrastType, firstGroup, analysisType, chartType)
            if (x.radios) {
              x.radios.forEach(r => {
                r.disabled = r.rule ? !_that.isSetTypeEnabled(paging, r.rule, lens, contrastType, firstGroup, analysisType, chartType) : false
              })
            }
            return x
          })
          if (el.list.every(x => !x.enabled)) el.enabled = false
        } else {
          el.list.forEach(x => (x.enabled = false))
        }
      })
    },
    isSetTypeEnabled(paging, rule, lens, contrastType, firstGroup, analysisType, chartType) {
      analysisType = _.cloneDeep(analysisType).toLowerCase()
      let enabled = false
      if (rule.behaviorType) {
        enabled = rule.behaviorType(analysisType)
        if (!enabled) return false
      }
      if (rule.propCheck) {
        enabled = rule.propCheck(lens.eventLen)
        if (!enabled) return false
      }
      if (rule.groupCheck) {
        enabled = rule.groupCheck(lens.groupLen)
        if (!enabled) return false
      }
      if (rule.contrastCheck) {
        enabled = rule.contrastCheck(contrastType)
        if (!enabled) return false
      }
      if (rule.fristGroupCheck) {
        enabled = rule.fristGroupCheck(firstGroup)
        if (!enabled) return false
      }
      if (rule.pagingIsNull) {
        enabled = rule.pagingIsNull(paging)
        if (!enabled) return false
      }
      if (rule.chartCheck) {
        enabled = rule.chartCheck(chartType)
        if (!enabled) return false
      }
      return enabled
    },
    setStyleChange(style) {
      if (style && style.code) {
        if(style.code)
        this.initStyleSet()
        if (style.code == 'allDimValues') {
          this.settings.isAllDimValues = true
        }
      }
      this.debounce()
    },

    setChange(isname) {
      this.debounce(isname)
    },
    changeTheme() {
      this.initTheme()
    },
    initTheme(init) {
      let mainColor = this.basicInfo.project.mainColor
      let chartMainColor = parseInt(this.settings.themeSet.chartMainColor)
      let curColor = ''
      if (chartMainColor == 0) {
        curColor = this.allColor['color' + mainColor]
      } else {
        curColor = this.allColor['color' + (chartMainColor - 1)]
      }

      this.chartThemeSet = {
        radios: [
          { label: '0', value: '跟随系统' },
          { label: '1', value: '沉稳蓝' },
          { label: '2', value: '活力橙' },
          { label: '3', value: '明亮青' },
          { label: '4', value: '浪漫红' },
          { label: '5', value: '梦幻紫' },
        ],
        color: [
          {
            label: '0',
            value: '使用分类色板',
            list: curColor[0],
          },
          {
            label: '1',
            value: '使用连续色板',
            list: curColor[1],
          },
        ],
      }
      if (!init) {
        this.debounce()
      }
    },
    setpropChange(item) {
      let filterset = item.filterSet
      const propset = item.propSet
      filterset.columns.forEach(x => {
        const prop = propset.props.find(p => p.fullAlias == x.fullAlias)
        if (prop) x.name = prop.title
      })
      this.settings = item
      this.propchange++
      this.debounce()
    },
    setfilterChange(item) {
      this.settings.filterSet = item.filterSet
      this.debounce()
    },
    debouncesetchange(isname) {
      const item = {
        setting: this.settings,
        isname: isname,
      }
      this.$emit('set-change', item)
    },
  },
}
</script>

<style lang="scss">
@import '../../css/styleset.scss';
</style>
